import React, { useCallback } from 'react';
import { useAtomValue } from 'jotai';
import { NotificationCountState, NotificationState, useNotificationsContext } from '@/context/notifications.context';
import { TNotification } from '@/common/user-notifications/notifications.types';
import { Icon } from '@/common/ui/icon';
import { Button } from '@/common/ui';
import { Notification } from './notification';


export type TNotificationsListProps = {
    onClose: () => void;
}
export const NotificationsList: React.FC<TNotificationsListProps> = ({
    onClose,
}) => {
    const {
        clearNotifications,
        removeNotification,
        isLoadingRemove,
        isLoadingClear,
        handleClickNotification,
    } = useNotificationsContext();

    const notifications = useAtomValue(NotificationState);
    const count = useAtomValue(NotificationCountState);

    const handlerClickClear = useCallback(async () => {
        await clearNotifications();
        onClose();
    }, [ clearNotifications, onClose ]);

    const handlerClickRemove = useCallback(async (notification: TNotification) => {
        await removeNotification(notification);
        if (count === 0) {
            onClose();
        }
    }, [ count, onClose, removeNotification ]);

    const handlerClickNotification = useCallback(async (notification: TNotification) => {
        await handleClickNotification(notification);
        onClose();
    }, [ handleClickNotification, onClose ]);

    return (
        <div className='notifications-list'>
            { count > 0 && (
                <>
                    <ul>
                        { notifications.map((notification) => (
                            <Notification
                                key={ notification.id }
                                notification={ notification }
                                onClick={ handlerClickNotification }
                                onClickRemove={ handlerClickRemove }
                                loadingRemove={ isLoadingRemove === notification.id }
                                disabledRemove={ isLoadingClear }
                            />
                        )) }
                    </ul>
                    <Button
                        className='notifications-clear'
                        onClick={ handlerClickClear }
                        loading={ isLoadingClear }
                        color='white'
                    >
                        <Icon name='broom' className='mr_5' color='grey'/>
                        Очистить
                    </Button>
                </>
            ) }
            { count === 0 && (
                <div className='color-grayDarken nowrap mt1 mb1 text-center'>
                    Нет новых уведомлений
                </div>
            ) }
        </div>
    );
}
