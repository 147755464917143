import React, { useCallback, useEffect, useState } from 'react';
import { UIRadio } from 'finbox-ui-kit';
import { ICreditorOrder } from '@/types/creaditors';
import { declMonth, formatMoney } from '@/utils';
import { Table } from '@/common/ui/table';
import { Button, Modal } from '@/common/ui';

type TOrdersPickerPopupProps = {
    open: boolean,
    orders: ICreditorOrder[],
    onClose: () => void,
    onPickOrder: (order: ICreditorOrder) => void,
}
export const OrdersPickerPopup: React.FC<TOrdersPickerPopupProps> = ({
    open,
    orders,
    onClose,
    onPickOrder,
}) => {
    const [ order, setOrder ] = useState<ICreditorOrder>(null);
    const [ error, setError ] = useState<string>(null);

    useEffect(() => {
        if (open) {
            setOrder(null);
        }
    }, [ open ]);

    const handlerClickSubmit = useCallback(() => {
        if (!order) {
            setError('Необходимо выбрать заявку');
            return;
        }
        onPickOrder(order);
    }, [ onPickOrder, order ]);

    const handlerChangeCheckbox = useCallback((e, { value }) => {
        setOrder(orders.find((i) => i.id === value));
        setError(null);
    }, [ orders ]);

    return (
        <Modal open={ open } onClose={ onClose } size='small'>
            <Modal.Header>Нет подходящих заявок</Modal.Header>
            <Modal.Content>
                <b>Выберите заявку кредитору</b>
                <Table hover>
                    <Table.Header>
                        <Table.Row>
                            <Table.Cell></Table.Cell>
                            <Table.Cell>Кредитор</Table.Cell>
                            <Table.Cell>Сумма кредита</Table.Cell>
                            <Table.Cell>Срок</Table.Cell>
                            <Table.Cell>% комиссии</Table.Cell>
                            <Table.Cell>Сумма комиссии</Table.Cell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        { orders?.map((item) => (
                            <Table.Row key={ item.id }>
                                <Table.Cell width={1}>
                                    <UIRadio
                                        className='mb0 mt-5'
                                        name='order'
                                        value={ item.id }
                                        onChange={handlerChangeCheckbox}
                                        checked={item.id === order?.id}
                                    />
                                </Table.Cell>
                                <Table.Cell>
                                    { item.bank.name }
                                </Table.Cell>
                                <Table.Cell>
                                    { formatMoney(item.loanSum) }
                                </Table.Cell>
                                <Table.Cell>
                                    { declMonth(item.loanTerm) }
                                </Table.Cell>
                                <Table.Cell>
                                    { item.declaredCommission }%
                                </Table.Cell>
                                <Table.Cell>
                                    { formatMoney(item.loanSum / 100 * item.declaredCommission) }
                                </Table.Cell>
                            </Table.Row>
                        )) }
                    </Table.Body>
                </Table>
                {error && (
                    <p className='color-red fz11'>{ error }</p>
                )}
            </Modal.Content>
            <Modal.Actions className='text-left'>
                <Button
                    content='Выбрать'
                    onClick={ handlerClickSubmit }
                    primary
                />
                <Button
                    content='Закрыть'
                    onClick={ onClose }
                />
            </Modal.Actions>
        </Modal>
    );
};
