import { companyScheme } from '@/components/leads/components/credit-from/schemas/company.scheme';
import { employeeScheme } from '@/components/leads/components/credit-from/schemas/employee.scheme';
import { primeTimeJobCompanyScheme } from '@/components/leads/components/credit-from/schemas/prime-time-job-company.scheme';
import { TWorkAndEducationForm } from '@/components/leads/components/credit-from/types';
import { Joi } from '@/libs/joi';

export const workAndEducationScheme = Joi.object<TWorkAndEducationForm>({
    education: Joi.string().required(),
    employmentType: Joi.string().required(),
    selfEmployedType: Joi.string().when('employmentType', {
        is: Joi.string().valid('Самозанятый', 'Неофициальный бизнес'),
        then: Joi.required(),
        otherwise: Joi.allow(null).optional(),
    }),
    selfEmployedINN: Joi.string().when('employmentType', {
        is: Joi.string().valid('Самозанятый'),
        then: Joi.required(),
        otherwise: Joi.allow(null).optional(),
    }),
    comment: Joi.string().allow(null),
    company: Joi.object().when('employmentType', {
        not: Joi.string().valid('Самозанятый', 'Пенсионер', 'Нетрудоустроенный', 'Неофициальный бизнес'),
        then: companyScheme.required(),
        otherwise: Joi.allow(null).optional(),
    }),
    employee: Joi.object().when('employmentType', {
        not: Joi.string().valid('Пенсионер', 'Нетрудоустроенный'),
        then: employeeScheme.required(),
        otherwise: Joi.allow(null).optional(),
    }),
    primeTimeJob: Joi.boolean().when('employmentType', {
        not: Joi.string().valid('Нетрудоустроенный'),
        then: Joi.required(),
        otherwise: Joi.allow(null).optional(),
    }),
    primeTimeJobCompany: Joi.object().when('primeTimeJob', {
        is: true,
        then: primeTimeJobCompanyScheme.required(),
        otherwise: Joi.allow(null).optional(),
    }),
});