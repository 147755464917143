import { Joi } from '@/libs/joi';

export const employeeScheme = Joi.object({
    startDate: Joi.string().required(),
    seniority: Joi.number().required(),
    position: Joi.string().when(Joi.ref('..employmentType'), {
        not: Joi.string().valid(null, 'Самозанятый', 'Пенсионер', 'Нетрудоустроенный', 'Неофициальный бизнес'),
        then: Joi.string().required(),
        otherwise: Joi.string().allow(null).optional(),
    }),
});