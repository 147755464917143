import React from 'react';
import cn from 'classnames';

import { IBlock, TBlockButtonProps, TBlockGroupProps, TBlockProps } from '@/common/ui/block/block.types';
import { Icon } from '@/common/ui';
import styles from './block.module.scss';


const Block: React.FC<TBlockProps> & IBlock = ({
    title,
    description,
    children,
    className,
    fixedHeader,
    fixedHeaderTop = 53,
    buttons,
    id,
    footer,
    error,
    noPadding,
    secondary,
    style,
}) => (
    <div
        id={ id }
        className={ cn(styles.block, className, {
            [styles.blockFixedHeader]: fixedHeader,
            [styles.blockErrored]: error,
            [styles.blockNoPadding]: noPadding,
            [styles.blockSecondary]: secondary,
        }) }
        style={ style }
    >
        <div className={ styles.blockHeader } style={ fixedHeader && { top: fixedHeaderTop } }>
            { title }
            { description && (
                <div className={ styles.blockHeaderDescription }>
                    { description }
                </div>
            ) }
            { (buttons && buttons.length > 0) && (
                <div className={ styles.blockButtons }>
                    { buttons.map((button, key) => {
                        if (React.isValidElement(button)) {
                            return button;
                        }
                        return (
                            <button
                                type='button'
                                key={ key }
                                onClick={ (button as TBlockButtonProps).onClick }
                                disabled={ (button as TBlockButtonProps).disabled }
                            >
                                <Icon
                                    type='light'
                                    name={ (button as TBlockButtonProps).icon }
                                    color={ (button as TBlockButtonProps).color }
                                />
                            </button>
                        );
                    }) }
                </div>
            ) }
            { error && (
                <div className='error-text'>{ error }</div>
            ) }
        </div>
        <div className={ styles.blockContent }>
            { children }
        </div>
        { footer && (
            <div className={ styles.blockFooter }>
                { footer }
            </div>
        ) }
    </div>
);

const BlockGroup: React.FC<TBlockGroupProps> = ({ className, children }) => (
    <div className={ cn(styles.blockGroup, className) }>
        { children }
    </div>
);


Block.Group = BlockGroup;


export {
    Block,
    Block as default,
};
