import React from 'react';
import cn from 'classnames';
import { TIconName } from '@/common/ui/icon/types';
import { Icon } from '@/common/ui';
import { TColor, TSize } from '@/types/common';
import { IconButton } from '@/common/ui/icon-button';
import styles from './message.module.scss';

type MessageProps = {
    content?: React.ReactNode
    children?: React.ReactNode
    className?: string
    icon?: TIconName
    color?: TColor
    size?: TSize
    inverted?: boolean
    onClickRemove?: () => void
    style?: React.CSSProperties
};

export function Message({
    content,
    children,
    className,
    icon,
    inverted,
    onClickRemove,
    size,
    style,
    color = 'grey',
}: MessageProps) {
    return (
        <div className={ cn(styles.message, className, {
            [styles.messageInverted]: inverted,
            [`-${ color }`]: color,
            [`-${ size }`]: size,
        }) } style={ style }>
            { icon && (
                <Icon name={ icon } type='light' className={ styles.messageIcon }/>
            ) }
            { onClickRemove && (
                <IconButton
                    icon='xmark'
                    iconType='light'
                    className={ styles.messageRemoveIcon }
                    onClick={ onClickRemove }
                />
            ) }
            <div className={ styles.messageContent }>
                { children || content }
            </div>
        </div>
    );
}