import React, { HTMLInputTypeAttribute, useEffect, useImperativeHandle, useRef, useState } from 'react';
import cn from 'classnames';
import { isDefined } from 'finbox-ui-kit/utils';
import { Nullable } from '@/types/_helpers';
import { ErrorText } from '@/common/ui/error-text';
import { Loader } from '@/common/ui/loader';
import { Icon } from '@/common/ui/icon';
import { useInput } from './input.hook';
import { InputInput } from './input-input';
import { GetInputValueType, TInputElement, TInputProps, TInputType } from './input.types';
import { InputMaskedInput } from './input-masked-input';
import styles from './input.module.scss';

const intRegex = /^[0-9]+$/;
const floatRegex = /^\d+[.,]?(\d+)?$/;
const extractNumber = (v: string): string => (v.match(/[\d,.eE+]+/g) as any)?.join('');


function InputElement<T extends TInputType = 'text'>(
    props: TInputProps<T>,
    forwardRef: React.ForwardedRef<HTMLInputElement>,
) {
    const {
        type = 'text' as TInputType,
        name,
        label,
        value,
        required,
        disabled,
        autoFocus,
        id,
        error,
        className,
        placeholder,
        inputMode,
        autoComplete,
        icon,
        iconType,
        mask,
        clearable,
        postfix,
        renderValue,
        float,
        readOnly,
        loading,
        size,
        rightIcon,
        onChange,
        onFocus,
        onBlur,
        onKeyDown,
        onPaste,
        onClick,
        onMouseDown,
        tabIndex,
        active,
        min,
        max,
        role,
        style,
        allowClearOnReadOnly,
        suggestionsAddon,
    } = props;

    const inputRef = useRef<HTMLInputElement>(null);
    const { id: realId } = useInput({ id, name });
    const [ localValue, setLocalValue ] = useState<Nullable<GetInputValueType<T>>>(value);

    useEffect(() => {
        setLocalValue(value);
    }, [ value ]);

    useImperativeHandle<HTMLInputElement | null, HTMLInputElement | null>(forwardRef, () => inputRef.current, []);

    function handlerChange(e: React.ChangeEvent<HTMLInputElement>) {
        let _value: string | number | null = e.target.value;
        let _localValue: any = e.target.value;

        if (isDefined(e.target.value) && _value !== '') {
            if (type === 'number' as TInputType) {
                _value = extractNumber(_value);
                const parser = float ? parseFloat : parseInt;
                const regex = float ? floatRegex : intRegex;

                _localValue = regex.test(_value) ? _value.replace(',', '.') : localValue;
                _value = parser(_value.replace(',', '.').replace(/\.$/, '') || '');
            }
        } else {
            _value = null;
            _localValue = null;
        }

        setLocalValue(_localValue);
        e.target.value = _value as string;

        onChange(e, {
            name,
            value: _value as any,
        });
    }

    function handlerFocus(e: React.FocusEvent<HTMLInputElement>) {
        if (onFocus) {
            onFocus(e, {
                name,
                value: e.target.value,
            });
        }
    }

    function handlerBlur(e: React.FocusEvent<HTMLInputElement>) {
        if (onBlur) {
            onBlur(e, {
                name,
                value: e.target.value,
            });
        }
    }

    function handlerClearClick(e: React.MouseEvent<HTMLButtonElement>) {
        e.preventDefault();
        e.stopPropagation();
        if (inputRef.current && !disabled && (!readOnly || allowClearOnReadOnly)) {
            inputRef.current.value = null as any;
            inputRef.current.dispatchEvent(new Event('change', { bubbles: true }));
            setLocalValue(null);
        }
    }

    const formattedValue = renderValue && isDefined(localValue)
        ? renderValue(localValue as string) as string || ''
        : localValue;

    const inputProps: React.InputHTMLAttributes<HTMLInputElement> = {
        id: realId,
        type: (type !== 'number' as TInputType ? type : 'text') as HTMLInputTypeAttribute,
        name: name,
        value: isDefined(localValue) ? formattedValue as string : '',
        disabled,
        required: required,
        placeholder: placeholder,
        inputMode: !inputMode && type === 'number' as TInputType ? 'decimal' : inputMode,
        autoComplete: autoComplete,
        autoFocus,
        readOnly,
        onChange: handlerChange,
        onFocus: handlerFocus,
        onBlur: handlerBlur,
        onKeyDown,
        onPaste,
        onClick,
        onMouseDown,
        tabIndex,
        min,
        max,
        role,
    };

    return (
        <div
            className={ cn(styles.input, className, {
                [styles.inputErrored]: !!error,
                [styles.inputActive]: active,
                [styles.inputIcon]: !!icon,
                [`-${ size }`]: size,
            }) }
            style={ style }
        >
            <div className={ styles.inputWrapper }>
                { icon && (
                    <Icon
                        className={ styles.inputIconIcon }
                        name={ icon }
                        type={ iconType as any }
                    />
                ) }

                { !mask && (<InputInput ref={ inputRef } { ...inputProps }/>) }
                { mask && (<InputMaskedInput ref={ inputRef } { ...inputProps } mask={ mask }/>) }

                {label && (
                    <label htmlFor={ realId }>
                        <span className={ styles.inputLabelText }>{ label }</span>
                        { required ? (<span className={ styles.inputRequiredAsteriks }>&nbsp;*</span>) : null }
                    </label>
                )}

                { postfix && (
                    <div className={ styles.inputRenderValue }>
                        <div className={ styles.inputRenderValueValue }>{ formattedValue }</div>
                        <div className={ styles.inputRenderValuePostfix }>{ postfix }</div>
                    </div>
                ) }

                { (clearable && !loading) && (
                    <button
                        type='button'
                        className={ styles.inputClear }
                        onClick={ handlerClearClick }
                        tabIndex={ -1 }
                    >
                        <Icon name='xmark'/>
                    </button>
                ) }

                { loading && (
                    <Loader
                        className={ styles.inputLoader }
                        size='tiny'
                        inverted
                    />
                ) }

                { !loading && rightIcon && (
                    <Icon
                        className={ cn(
                            styles.inputRightIcon,
                            // { '-with-info': !!info },
                        ) }
                        name={ rightIcon }
                        size='normal'
                    />
                ) }

            </div>

            { suggestionsAddon || null }

            { (error && typeof error === 'string') && (
                <ErrorText text={ error }/>
            ) }

            { (error && typeof error === 'object') && (
                <ErrorText text={ error.message }/>
            ) }
        </div>
    );
}

export const Input: TInputElement = React.forwardRef(InputElement) as TInputElement;