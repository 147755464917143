import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import get from 'lodash/get';

import { loginScheme } from '@/components/login/schemas/login.scheme';
import { Input } from '@/common/ui/input';
import { Button, Message } from '@/common/ui';


type TLoginForm = {
    login: string,
    password: string,
}

type TLoginFormProps = {
    isFetching: boolean;
    onSubmit: (fields: TLoginForm) => void;
    error: string
}

const LoginForm = ({ isFetching, onSubmit, error }: TLoginFormProps) => {
    const { control, handleSubmit, formState: { errors } } = useForm<TLoginForm>({
        resolver: loginScheme,
        shouldUseNativeValidation: false,
    });

    const submitHandler = (fields) => {
        onSubmit(fields);
    };

    return (
        <form className='mt1' onSubmit={handleSubmit(submitHandler)} autoComplete='off' noValidate>
            <Controller
                control={ control }
                name='login'
                render={ ({ field }) => (
                    <Input
                        { ...field }
                        className='mb1'
                        type='text'
                        label='Логин'
                        error={ get(errors, 'login.message') }
                        disabled={isFetching}
                        autoComplete='off'
                        required
                    />
                ) }
            />
            <Controller
                control={ control }
                name='password'
                render={ ({ field }) => (
                    <Input
                        className='mb1'
                        type='password'
                        label='Пароль'
                        { ...field }
                        error={ get(errors, 'password.message') }
                        disabled={isFetching}
                        autoComplete='off'
                        required
                    />
                ) }
            />
            {error && (
                <Message color='red' className='mb1'>{ error }</Message>
            )}
            <Button
                type='submit'
                className='loginScreen-submit'
                content='Войти'
                disabled={isFetching}
                loading={isFetching}
                primary
            />
        </form>
    );
};

export {
    LoginForm,
};
