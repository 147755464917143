import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useFormContext, FieldError } from 'react-hook-form';
import get from 'lodash/get';
import { useDebounce } from 'finbox-ui-kit/utils/hooks';
import {
    MCompanyActivityType,
    MEducation,
    MEmploymentType,
    MSelfEmployedType,
    MYesNoOptions,
} from '@/components/leads/components/credit-from/consts';
import { API_URLS } from '@/consts';
import { PrimeTimeJobForm } from '@/components/leads/components/credit-from/components/prime-time-job-form';
import { TWorkAndEducationForm } from '@/components/leads/components/credit-from/types';
import { useApiClient } from '@/libs/api-client/use-api-client';
import { InputSuggestions, InputSuggestionsOptions } from '@/common/ui/input-suggestions';
import { Input } from '@/common/ui/input';
import { Textarea } from '@/common/ui/textarea';
import { Dropdown } from '@/common/ui/dropdown';
import { Grid } from '@/common/ui/grid';
import { Checkbox } from '@/common/ui/checkbox';
import { Nullable } from '@/types/_helpers';
import { InputAddress } from '@/common/ui/input-address';
import { TInputHandlerChange } from '@/common/ui/input/input.types';
import { Divider } from 'common/ui';

type WorkAndEducationFormType = any & {
    [x: string]: TWorkAndEducationForm
}

type FinancesFormProps = {
    path: string
}

export function WorkAndEducationForm({ path }: FinancesFormProps) {
    const { debounce } = useDebounce({ timeout: 300 });
    const {
        control,
        formState: { errors },
        watch,
        setValue,
        clearErrors,
    } = useFormContext<WorkAndEducationFormType>();

    const employmentType = watch(`${ path }.employmentType`);
    const primeTimeJob = watch(`${ path }.primeTimeJob`);
    const companyAddress = watch(`${ path }.company.address`);
    const isEqualAddresses = watch(`${ path }.company.isEqualAddresses`);

    const [ companySuggestions, setCompanySuggestions ] = useState([]);
    const [ companyScopeSuggestions, setCompanyScopeSuggestions ] = useState([]);

    const { loading: isLoadingOrganization, fetch: fetchOrganization } = useApiClient({
        url: API_URLS.DICTIONARIES.DADATA.ORGANIZATION,
    });
    const { loading: isLoadingScope, fetch: fetchScope } = useApiClient({
        url: API_URLS.DICTIONARIES.DADATA.OKVED2,
    });

    useEffect(() => {
        if (isEqualAddresses) {
            setValue(
                `${ path }.company.factAddress`,
                companyAddress,
            );
            clearErrors(`${ path }.company.factAddress`);
        }
    }, [ isEqualAddresses, clearErrors, companyAddress, setValue, path ]);


    const handlerChangeCompany = useCallback((formChangeHandler) => (value: Nullable<any>, { option }: InputSuggestionsOptions) => {
        formChangeHandler(value);
        if (option && option.data && option.data.data) {
            setValue(`${ path }.company.inn`, option.data.data.inn);
            setValue(`${ path }.company.ogrn`, option.data.data.ogrn);
            setValue(`${ path }.company.address`, option.data.data.address.unrestricted_value);
            clearErrors([
                `${ path }.company.inn`,
                `${ path }.company.ogrn`,
                `${ path }.company.address`,
            ]);
        }
        if (value) {
            debounce(async () => {
                const { suggestions } = await fetchOrganization({
                    query: value,
                });
                setCompanySuggestions(suggestions.map((i) => ({
                    text: `${ i.value } (${ i.data.address.value })`,
                    value: i.value,
                    data: i,
                })));
            });
        }
    }, [ clearErrors, debounce, fetchOrganization, path, setValue ]);

    const handlerChangeScope = useCallback((formChangeHandler) => (value) => {
        formChangeHandler(value);

        if (value) {
            debounce(async () => {
                const { suggestions } = await fetchScope({
                    query: value,
                });
                setCompanyScopeSuggestions(suggestions
                    .filter((i) => i.data.kod?.split('.')?.length < 3)
                    .map((i) => ({
                        text: i.value,
                        value: i.value,
                        data: i,
                    })));
            });
        }
    }, [ debounce, fetchScope ]);

    const handlerChangeEmploymentType: (...event: any[]) => TInputHandlerChange<string> = useCallback((formChangeHandler) => (e) => {
        formChangeHandler(e);
        clearErrors([
            `${ path }.selfEmployedType`,
            `${ path }.selfEmployedINN`,
            `${ path }.primeTimeJob`,
            `${ path }.company`,
            `${ path }.employee`,
            `${ path }.primeTimeJobCompany`,
        ]);
        setValue(`${ path }.selfEmployedType`, null);
        setValue(`${ path }.selfEmployedINN`, null);
        setValue(`${ path }.primeTimeJob`, null);

        setValue(`${ path }.company.phone`, null);
        setValue(`${ path }.company.address`, null);
        setValue(`${ path }.company.inn`, null);
        setValue(`${ path }.company.isEqualAddresses`, false);
        setValue(`${ path }.company.factAddress`, null);
        setValue(`${ path }.company.ogrn`, null);
        setValue(`${ path }.company.activityType`, null);
        setValue(`${ path }.company.scope`, null);
        setValue(`${ path }.company.employeesCount`, null);

        setValue(`${ path }.employee.position`, null);
        setValue(`${ path }.employee.startDate`, null);
        setValue(`${ path }.employee.seniority`, null);
    }, [ clearErrors, path, setValue ]);


    return (
        <Grid stackable>
            <Grid.Row columns={ 3 }>
                <Grid.Col>
                    <Controller
                        control={ control }
                        name={ `${ path }.education` }
                        render={ ({ field }) => (
                            <Dropdown
                                { ...field }
                                label='Образование'
                                options={ MEducation.map((item) => ({
                                    text: item,
                                    value: item,
                                })) }
                                error={ (get(errors, `${ path }.education`) as FieldError)?.message }
                                required
                                clearable
                            />
                        ) }
                    />
                </Grid.Col>
                <Grid.Col>
                    <Controller
                        control={ control }
                        name={ `${ path }.employmentType` }
                        render={ ({ field }) => (
                            <Dropdown
                                { ...field }
                                label='Тип занятости'
                                options={ MEmploymentType.map((item) => ({
                                    text: item,
                                    value: item,
                                })) }
                                error={ (get(errors, `${ path }.employmentType`) as FieldError)?.message }
                                onChange={handlerChangeEmploymentType(field.onChange)}
                            />
                        ) }
                    />
                </Grid.Col>
                { [ 'Самозанятый', 'Неофициальный бизнес' ].includes(employmentType) && (
                    <>
                        <Grid.Col>
                            <Controller
                                control={ control }
                                name={ `${ path }.selfEmployedType` }
                                render={ ({ field }) => (
                                    <InputSuggestions
                                        { ...field }
                                        type='text'
                                        label='Сфера деятельности'
                                        suggestions={ MSelfEmployedType.map((item) => ({
                                            text: item,
                                            value: item,
                                        })) }
                                        error={ (get(errors, `${ path }.selfEmployedType`) as FieldError)?.message }
                                        clearable
                                    />
                                ) }
                            />
                        </Grid.Col>
                        { employmentType === 'Самозанятый' && (
                            <Grid.Col>
                                <Controller
                                    control={ control }
                                    name={ `${ path }.selfEmployedINN` }
                                    render={ ({ field }) => (
                                        <Input
                                            { ...field }
                                            type='text'
                                            label='ИНН'
                                            autoComplete='off'
                                            mask='999999999999'
                                            placeholder='000000000000'
                                            error={ (get(errors, `${ path }.selfEmployedINN`) as FieldError)?.message }
                                            clearable
                                        />
                                    ) }
                                />
                            </Grid.Col>
                        ) }
                    </>
                ) }
            </Grid.Row>
            { ![ null, undefined, 'Самозанятый', 'Пенсионер', 'Нетрудоустроенный', 'Неофициальный бизнес' ].includes(employmentType) && (
                <>
                    <Grid.Row columns={ 3 }>
                        <Grid.Col colspan={ 2 }>
                            <Controller
                                control={ control }
                                name={ `${ path }.company.name` }
                                render={ ({ field }) => (
                                    <InputSuggestions
                                        { ...field }
                                        type='text'
                                        label='Название организации'
                                        suggestions={ companySuggestions }
                                        loading={ isLoadingOrganization }
                                        placeholder='Начните вводить название или ИНН'
                                        filtering={ false }
                                        error={ (get(errors, `${ path }.company.name`) as FieldError)?.message }
                                        onChange={ handlerChangeCompany(field.onChange) }
                                        clearable
                                    />
                                ) }
                            />
                        </Grid.Col>
                        <Grid.Col>
                            <Controller
                                control={ control }
                                name={ `${ path }.company.phone` }
                                render={ ({ field }) => (
                                    <Input
                                        { ...field }
                                        type='tel'
                                        label='Рабочий телефон'
                                        mask='+7 (999) 999-99-99'
                                        placeholder='+7 (000) 000-00-00'
                                        error={ (get(errors, `${ path }.company.phone`) as FieldError)?.message }
                                        clearable
                                    />
                                ) }
                            />
                        </Grid.Col>
                    </Grid.Row>
                    <Grid.Row columns={ 3 }>
                        <Grid.Col colspan={ 2 }>
                            <Controller
                                control={ control }
                                name={ `${ path }.company.address` }
                                render={ ({ field }) => (
                                    <InputAddress
                                        { ...field }
                                        label='Юридический адрес'
                                        autoComplete='off'
                                        error={ (get(errors, `${ path }.company.address`) as FieldError)?.message }
                                        clearable
                                    />
                                ) }
                            />
                        </Grid.Col>
                        <Grid.Col>
                            <Controller
                                control={ control }
                                name={ `${ path }.company.inn` }
                                render={ ({ field }) => (
                                    <Input
                                        { ...field }
                                        type='number'
                                        label='ИНН'
                                        error={ (get(errors, `${ path }.company.inn`) as FieldError)?.message }
                                        clearable
                                    />
                                ) }
                            />
                        </Grid.Col>
                    </Grid.Row>
                    <Grid.Row columns={ 3 }>
                        <Grid.Col colspan={ 2 }>
                            <Controller
                                control={ control }
                                name={ `${ path }.company.isEqualAddresses` }
                                render={ ({ field }) => (
                                    <Checkbox
                                        label='Совпадает с юридическим'
                                        checked={ field.value }
                                        onChange={ (e, { checked }) => {
                                            field.onChange(checked);
                                        } }
                                        disabled={ !companyAddress }
                                    />
                                ) }
                            />
                        </Grid.Col>
                    </Grid.Row>
                    <Grid.Row columns={ 3 }>
                        <Grid.Col colspan={ 2 }>
                            <Controller
                                control={ control }
                                name={ `${ path }.company.factAddress` }
                                render={ ({ field }) => (
                                    <InputAddress
                                        { ...field }
                                        label='Фактический адрес'
                                        className='mb_5'
                                        disabled={ isEqualAddresses }
                                        autoComplete='off'
                                        error={ (get(errors, `${ path }.company.factAddress`) as FieldError)?.message }
                                        clearable
                                    />
                                ) }
                            />
                        </Grid.Col>
                        <Grid.Col>
                            <Controller
                                control={ control }
                                name={ `${ path }.company.ogrn` }
                                render={ ({ field }) => (
                                    <Input
                                        { ...field }
                                        type='number'
                                        label='ОГРН'
                                        error={ (get(errors, `${ path }.company.ogrn`) as FieldError)?.message }
                                        clearable
                                    />
                                ) }
                            />
                        </Grid.Col>
                    </Grid.Row>
                    <Grid.Row columns={ 3 }>
                        <Grid.Col>
                            <Controller
                                control={ control }
                                name={ `${ path }.company.activityType` }
                                render={ ({ field }) => (
                                    <Dropdown
                                        { ...field }
                                        label='Вид деятельности организации'
                                        options={ MCompanyActivityType.map((item) => ({
                                            text: item,
                                            value: item,
                                        })) }
                                        error={ (get(errors, `${ path }.company.activityType`) as FieldError)?.message }
                                        clearable
                                    />
                                ) }
                            />
                        </Grid.Col>
                        <Grid.Col>
                            <Controller
                                control={ control }
                                name={ `${ path }.company.scope` }
                                render={ ({ field }) => (
                                    <InputSuggestions
                                        { ...field }
                                        type='text'
                                        label='Сфера деятельности компании'
                                        suggestions={ companyScopeSuggestions }
                                        loading={ isLoadingScope }
                                        onChange={handlerChangeScope(field.onChange)}
                                        filtering={ false }
                                        error={ (get(errors, `${ path }.company.scope`) as FieldError)?.message }
                                        clearable
                                    />
                                ) }
                            />
                        </Grid.Col>
                        <Grid.Col>
                            <Controller
                                control={ control }
                                name={ `${ path }.company.employeesCount` }
                                render={ ({ field }) => (
                                    <Input
                                        { ...field }
                                        type='number'
                                        label='Количество сотрудников'
                                        error={ (get(errors, `${ path }.company.employeesCount`) as FieldError)?.message }
                                        clearable
                                    />
                                ) }
                            />
                        </Grid.Col>
                    </Grid.Row>
                    <Grid.Row columns={ 3 }>
                        <Grid.Col>
                            <Controller
                                control={ control }
                                name={ `${ path }.employee.position` }
                                render={ ({ field }) => (
                                    <Input
                                        { ...field }
                                        type='text'
                                        label='Наименование должности'
                                        error={ (get(errors, `${ path }.employee.position`) as FieldError)?.message }
                                        clearable
                                    />
                                ) }
                            />
                        </Grid.Col>
                    </Grid.Row>
                </>
            ) }
            { ![ null, undefined, 'Пенсионер', 'Нетрудоустроенный' ].includes(employmentType) && (
                <Grid.Row columns={ 3 }>
                    <Grid.Col>
                        <Controller
                            control={ control }
                            name={ `${ path }.employee.startDate` }
                            render={ ({ field }) => (
                                <Input
                                    { ...field }
                                    type='text'
                                    label='Трудовой стаж на последнем месте работы с'
                                    mask='99.9999'
                                    placeholder='мм.гггг'
                                    error={ (get(errors, `${ path }.employee.startDate`) as FieldError)?.message }
                                    clearable
                                    required
                                />
                            ) }
                        />
                    </Grid.Col>
                    <Grid.Col>
                        <Controller
                            control={ control }
                            name={ `${ path }.employee.seniority` }
                            render={ ({ field }) => (
                                <Input
                                    { ...field }
                                    type='number'
                                    label='Общий трудовой стаж'
                                    postfix='лет'
                                    error={ (get(errors, `${ path }.employee.seniority`) as FieldError)?.message }
                                    clearable
                                    required
                                />
                            ) }
                        />
                    </Grid.Col>
                </Grid.Row>
            ) }
            { employmentType === 'Нетрудоустроенный' && (
                <Grid.Row columns={ 1 }>
                    <Grid.Col>
                        <Controller
                            control={ control }
                            name={ `${ path }.comment` }
                            render={ ({ field }) => (
                                <Textarea
                                    { ...field }
                                    label='Комментарий'
                                    error={ (get(errors, `${ path }.comment`) as FieldError)?.message }
                                    clearable
                                />
                            ) }
                        />
                    </Grid.Col>
                </Grid.Row>
            ) }
            { ![ null, undefined, 'Нетрудоустроенный' ].includes(employmentType) && (
                <Grid.Row columns={ 3 } className='p0'>
                    <Grid.Col>
                        <Controller
                            control={ control }
                            name={ `${ path }.primeTimeJob` }
                            render={ ({ field }) => (
                                <Dropdown
                                    { ...field }
                                    label='Наличие работы по совместительству'
                                    options={ MYesNoOptions }
                                    error={ (get(errors, `${ path }.primeTimeJob`) as FieldError)?.message }
                                    clearable
                                />
                            ) }
                        />
                    </Grid.Col>
                </Grid.Row>
            ) }
            { primeTimeJob && (
                <>
                    <Grid.Row columns={ 1 }>
                        <Grid.Col>
                            <Divider
                                content='Информация о работе по совместительству'
                            />
                        </Grid.Col>
                        <Grid.Col>
                            <PrimeTimeJobForm path={ `${ path }.primeTimeJobCompany` }/>
                        </Grid.Col>
                    </Grid.Row>
                </>
            ) }
        </Grid>
    );
}
