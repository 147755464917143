import React, { useEffect } from 'react';
import cn from 'classnames';

import { UIBreadcrumbs } from 'finbox-ui-kit/components/breadcrumbs';
import { Button } from '@/common/ui';
import { PageButtons } from '@/common/ui/page/page-buttons';
import { PageMenu } from '@/common/ui/page/page-menu';
import { IPage, TPageContentProps, TPageProps, TRightSideBarWrapperProps } from './page';
import './page.style.scss';


const Page: React.FC<TPageProps> & IPage = (
    {
        title,
        children,
        buttons,
        breadCrumbs,
        noContentPadding,
        rightSideBar,
        beforeContent,
        documentTitle,
        flexContent,
        className,
        onBackButtonClick,
        indexLink,
        menu,
    },
) => {
    useEffect(() => {
        if (documentTitle) {
            document.title = documentTitle;
        } else if (typeof title === 'string') {
            document.title = title;
        }
    }, [ title, documentTitle ]);

    return (
        <section
            className={ cn('ui-page', {
                '-with-right-side-bar': rightSideBar,
                '-flex-content': flexContent,
                '-with-menu': menu,
            }, className) }
        >
            <header className='ui-page-header'>
                { onBackButtonClick && (
                    <Button
                        className='ui-page-back-button'
                        onClick={ onBackButtonClick }
                        icon='chevron-left'
                        color='white'
                    />
                ) }
                <div className='ui-page-header-content'>
                    <h1>{ title }</h1>
                    { breadCrumbs && (
                        <UIBreadcrumbs items={ breadCrumbs } indexLink={indexLink} />
                    ) }
                </div>
                { buttons && <PageButtons buttons={buttons} /> }
                { menu && <PageMenu menu={menu} /> }
            </header>
            <div className={ cn('ui-page-content-wrapper', {
                '-no-padding': noContentPadding,
            }) }>
                { beforeContent }
                { children }
            </div>
        </section>
    );
}

const RightSideBarWrapper: React.FC<TRightSideBarWrapperProps> = ({ sticky, children }) => (
    <div className='ui-page-right-side-bar-wrapper'>
        <div className={ cn('ui-page-right-side-bar', { '-sticky': sticky }) }>
            { children }
        </div>
    </div>
);

const LeftSideBarWrapper: React.FC<TRightSideBarWrapperProps> = ({ sticky, children }) => (
    <div className='ui-page-left-side-bar-wrapper'>
        <div className={ cn('ui-page-left-side-bar', { '-sticky': sticky }) }>
            { children }
        </div>
    </div>
);

const PageContent: React.FC<TPageContentProps> = ({ children, attached, className }) => (
    <div className={ cn('ui-page-content', { '-attached': attached }, className) }>
        { children }
    </div>
);

Page.RightSideBarWrapper = RightSideBarWrapper;
Page.LeftSideBarWrapper = LeftSideBarWrapper;
Page.Content = PageContent;


export {
    Page,
    Page as default,
};
