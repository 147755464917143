import { TFilePickerComplex } from './types';
import { FilePicker } from './file-picker'
import { FilePickerControlled } from './file-picker-controlled';

(FilePicker as TFilePickerComplex).Controlled = FilePickerControlled;
const FilePickerComplex: TFilePickerComplex = FilePicker as TFilePickerComplex;

export {
    FilePickerComplex as FilePicker,
    FilePickerControlled,
};