import React, { ChangeEventHandler, useMemo, useRef } from 'react';
import cn from 'classnames';
import { UIInfo } from 'finbox-ui-kit';
import { getRefTarget, MakeUISyntheticChangeEvent } from 'finbox-ui-kit/utils';
import { Icon } from '@/common/ui';
import { CheckboxProps } from './checkbox.types';
import styles from './checkbox.module.scss';


export const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(function UICheckbox({
    label,
    checked,
    value,
    className,
    name,
    id,
    onChange,
    disabled,
    error,
    inline,
    data,
    required,
    info,
}, ref) {
    const inputRef = useRef<HTMLInputElement>(null);
    const handlerChange: ChangeEventHandler<HTMLInputElement> = (e) => {
        onChange(
            MakeUISyntheticChangeEvent(getRefTarget(inputRef, ref), value),
            {
                name,
                value,
                checked: e.target.checked,
                data,
            },
        )
    }
    const _error = useMemo(() => {
        if (!error) {
            return null;
        }
        if (typeof error === 'string') {
            return error;
        }
        if (Array.isArray(error)) {
            return error?.[0]?.message;
        }
        return error.message;
    }, [ error ]);

    return (
        <div className={ cn(styles.checkbox, className, {
            [styles.checkboxChecked]: checked,
            [styles.checkboxDisabled]: disabled,
            [styles.checkboxInline]: inline,
            [styles.checkboxNoLabel]: !label,
        }) }>
            <label htmlFor={ id || name }>
                <input
                    ref={ ref }
                    type='checkbox'
                    checked={ checked }
                    value={ value }
                    id={ id || name }
                    name={ name || id }
                    onChange={ handlerChange }
                    disabled={disabled}
                />
                <div className={ styles.checkboxInput }>
                    <Icon
                        name='check'
                        color='black'
                        className={styles.checkboxCheckmark}
                    />
                </div>
                { label }
                { info && (
                    <UIInfo
                        className='ml-5'
                        content={ info }
                        showOnHover
                    />
                )}
                { required ? (<span className='color-red'>&nbsp;*</span>) : null }
            </label>
            { error && (
                <div className={styles.checkboxError}>{ _error }</div>
            ) }
        </div>
    );
});
