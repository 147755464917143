import React from 'react';
import cn from 'classnames';
import { IBox, TBoxGroupProps, TBoxProps } from '@/common/ui/box/box';
import styles from './box.module.scss';


const Box: React.FC<TBoxProps> & IBox = ({
    children,
    className,
    noPadding,
    noBorder,
    fluid,
}) => (
    <div className={cn(styles.box, className, {
        [styles.NoPadding]: noPadding,
        [styles.NoBorder]: noBorder,
        [styles.Fluid]: fluid,
    })}>
        { children }
    </div>
);

const BlockGroup: React.FC<TBoxGroupProps> = ({ className, children }) => (
    <div className={cn(styles.boxGroup, className)}>
        { children }
    </div>
);

Box.Group = BlockGroup;

export {
    Box,
    Box as default,
}
