import React, { useCallback, useEffect, useState } from 'react';
import { useSocketsContext } from '@/libs/sockets/sockets.context';
import { SOCKET_EVENT } from '@/consts';
import { Button, Icon, Modal, useModal } from '@/common/ui';


interface IAppContext {
    isSystemDown: boolean;
    isSystemForceUpdate: boolean;
}


const AppContext = React.createContext<IAppContext>({
    isSystemDown: false,
    isSystemForceUpdate: false,
});

export const useAppContext = () => React.useContext(AppContext);

export const AppContextProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
    const [ isSystemForceUpdate, setIsSystemForceUpdate ] = useState(false);
    const { show, hide, props, isOpen } = useModal();
    const { subscribe, unsubscribe } = useSocketsContext();
    const handlerSystemDown = useCallback(() => {
        show();
    }, [ show ]);
    const handlerSystemUp = useCallback(() => {
        hide();
    }, [ hide ]);
    const handlerSystemForceUpdate = useCallback(() => {
        setIsSystemForceUpdate(true);
        show();
    }, [ show ]);

    useEffect(() => {
        subscribe(SOCKET_EVENT.SYSTEM_DOWN, handlerSystemDown);
        subscribe(SOCKET_EVENT.SYSTEM_UP, handlerSystemUp);
        subscribe(SOCKET_EVENT.SYSTEM_FORCE_UPDATE_FRONTEND, handlerSystemForceUpdate);
        return () => {
            unsubscribe(SOCKET_EVENT.SYSTEM_DOWN, handlerSystemDown);
            unsubscribe(SOCKET_EVENT.SYSTEM_UP, handlerSystemUp);
            unsubscribe(SOCKET_EVENT.SYSTEM_FORCE_UPDATE_FRONTEND, handlerSystemForceUpdate);
        };
    }, [ handlerSystemDown, handlerSystemForceUpdate, handlerSystemUp, subscribe, unsubscribe ]);

    return (
        <AppContext.Provider value={ {
            isSystemDown: isOpen,
            isSystemForceUpdate,
        } }>
            { children }
            <Modal
                { ...props }
                size='small'
                closeOnDimmerClick={false}
            >
                <Modal.Header>
                    <div className='text-center' style={{ flex: 1 }}>
                        Обновление системы
                    </div>
                </Modal.Header>
                <Modal.Content>
                    { !isSystemForceUpdate && (
                        <div className='text-center'>
                            В данный момент система перезапускается.<br/> Пожалуйста, подождите пару минут. <br/> <br/>
                            <div style={{ fontSize: 40 }}>
                                <Icon
                                    type='thin'
                                    size='huge'
                                    name='gear-complex-code'
                                    color='grey'
                                    style={{
                                        width: 88,
                                        height: 80,
                                    }}
                                    rotate
                                />
                            </div>
                            <br/> <br/>
                        </div>
                    ) }
                    { isSystemForceUpdate && (
                        <div className='text-center'>
                            Вышло критическое обновление <br/><br/>
                            Требуется обновить страницу <b>Ctrl&nbsp;+&nbsp;F5</b>
                            <br/><br/>
                            <Button
                                content='Перезагрузить'
                                onClick={ () => {
                                    window.location.reload();
                                } }
                                primary
                            />
                        </div>
                    ) }
                </Modal.Content>
            </Modal>
        </AppContext.Provider>
    );
};
