import React, { useCallback, useEffect, useRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import get from 'lodash/get';

import { refGetter } from '@/common/finbox-ui-kit/src/utils';
import { Input } from '@/common/ui/input';
import { Button, Message } from '@/common/ui';


export type TCodeForm = {
    code: string,
}

type TLoginFormProps = {
    timer: number,
    isFetching: boolean,
    onSubmit: (code: string) => void,
    onResend: () => void,
    onCancel: () => void,
    error: string
}

const ConfirmCodeForm = ({
    isFetching, onSubmit, onResend, onCancel, timer, error,
}: TLoginFormProps) => {
    const codeRef = useRef<HTMLInputElement>();

    const {
        control,
        watch,
        setValue ,
        formState: { errors },
        handleSubmit,
    } = useFormContext<TCodeForm>();

    const code = watch('code');

    useEffect(() => {
        if (code?.length == 6) {
            onSubmit(code);
            return;
        }
    }, [ code, onSubmit ]);

    const focusInput = useCallback(() => {
        if (codeRef && codeRef.current) {
            codeRef.current.focus();
        }
    }, [ codeRef ]);

    useEffect(() => {
        focusInput();
    }, [ focusInput, isFetching ]);

    const submitHandler = useCallback(async (fields) => {
        try {
            await onSubmit(fields.code);
        } catch (e) {
            setValue('code', '');
        }
    }, [ onSubmit, setValue ]);

    const resendClickHandler = useCallback(() => {
        onResend();
        setValue('code', '');
    }, [ onResend, setValue ]);

    return (
        <form className='mt1' onSubmit={handleSubmit(submitHandler)} noValidate>
            <Controller
                control={ control }
                name='code'
                render={ ({ field }) => (
                    <Input
                        { ...field }
                        ref={ refGetter(codeRef, field.ref) }
                        type='text'
                        label='Код подтверждения'
                        error={ get(errors, 'code.message') }
                        disabled={isFetching}
                        autoComplete='off'
                        mask='999999'
                        inputMode='numeric'
                        required
                    />
                ) }
            />
            {error && (
                <Message color='red' className='mb1'>{ error }</Message>
            )}
            <div className='mt1'>
                <Button
                    type='submit'
                    className='loginScreen-submit'
                    content='Подтвердить'
                    disabled={isFetching || code?.length !== 6}
                    loading={isFetching}
                    primary
                />
                <Button
                    className='ml-5'
                    type='button'
                    onClick={onCancel}
                    disabled={isFetching}
                    color='white'
                >
                    Назад
                </Button>
                <Button
                    type='button'
                    className='ml-5'
                    onClick={resendClickHandler}
                    disabled={isFetching || timer !== 0}
                    color='white'
                >
                    Отправить повторно {(timer > 0) && `(${ timer })`}
                </Button>
            </div>
        </form>
    );
};

export {
    ConfirmCodeForm,
};
