import { Controller, FieldValues } from 'react-hook-form'
import React, { ReactElement } from 'react';
import { FilePicker } from '@/common/ui/file-picker/file-picker';
import { FilePickerControlledProps } from '@/common/ui/file-picker/types';


export function FilePickerControlled<TFieldValues extends FieldValues = FieldValues>({
    control,
    ...props
}: FilePickerControlledProps<TFieldValues>): ReactElement {
    return (
        <Controller
            control={ control }
            name={ props.name }
            render={ ({ field }) => (
                <FilePicker
                    { ...field }
                    { ...props }
                />
            ) }
        />
    )
}