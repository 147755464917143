import React, { useState } from 'react';
import { useAuthContext } from '@/context/auth.context';
import { API_PROFILE_CHANGE_PASSWORD } from '@/components/profile/_consts';
import { ChangePasswordForm } from '@/common/change-password-form';
import { useApiClient } from '@/libs/api-client/use-api-client';
import { memoizedRefreshToken } from '@/libs/jwt-token';
import { Button, Modal } from '@/common/ui';


const ChangePasswordRequired = () => {
    const { doAuthUser } = useAuthContext();
    const [ isPasswordChanged, setIsPasswordChanged ] = useState(false);

    const { fetch, loading } = useApiClient({
        method: 'put',
        url: API_PROFILE_CHANGE_PASSWORD,
        successMessage: 'Пароль изменён',
    });

    const handlerSubmit = async (password: string) => {
        await fetch({
            password,
        });
        setIsPasswordChanged(true);
        await doAuthUser(await memoizedRefreshToken());
    };

    if (isPasswordChanged) {
        return null;
    }

    return (
        <Modal open size='tiny'>
            <Modal.Header>Требуется сменить пароль</Modal.Header>
            <Modal.Content>
                <ChangePasswordForm
                    loading={ loading }
                    onSubmit={ handlerSubmit }
                />
            </Modal.Content>
            <Modal.Actions>
                <Button
                    type='submit'
                    form='change-password-form'
                    content='Сохранить'
                    loading={ loading }
                    primary
                />
            </Modal.Actions>
        </Modal>
    );
};

export {
    ChangePasswordRequired,
};
