import React, { useImperativeHandle, useRef } from 'react';
import cn from 'classnames';
import { useInput } from '@/common/ui/input/input.hook';
import { ErrorText } from '@/common/ui/error-text';
import { Icon } from '@/common/ui/icon';
import { Loader } from '@/common/ui/loader';
import { TextareaProps, TTextareaElement } from './textarea.types';
import styles from './textarea.module.scss';


const TextareaElement = function Textarea({
    ref,
    id,
    name,
    value,
    onChange,
    rows = 4,
    cols,
    error,
    label,
    required,
    disabled,
    className,
    placeholder,
    clearable,
    readOnly,
    loading,
}: TextareaProps) {
    const inputRef = useRef<HTMLTextAreaElement>(null);
    useImperativeHandle<HTMLTextAreaElement | null, HTMLTextAreaElement | null>(ref, () => inputRef.current, []);
    const { id: realId } = useInput({ id, name });

    function handlerClearClick(e: React.MouseEvent<HTMLButtonElement>) {
        e.preventDefault();
        e.stopPropagation();
        if (inputRef.current) {
            inputRef.current.value = null as any;
            inputRef.current.dispatchEvent(new Event('change', { bubbles: true }));
        }
    }

    return (
        <div className={ cn(styles.textarea, className, {
            [styles.textareaErrored]: !!error,
            [styles.textareaNotEmpty]: !!value,
        }) }>
            <div className={ styles.textareaWrapper }>
                <textarea
                    id={ realId }
                    ref={ inputRef }
                    name={ name }
                    rows={ rows }
                    cols={ cols }
                    value={ value || '' }
                    onChange={ (e) => onChange(
                        e.target.value, {
                            name,
                            value: e.target.value,
                        },
                    ) }
                    aria-multiline={ true }
                    data-empty={ !value }
                    disabled={ disabled }
                    required={ required }
                    readOnly={ readOnly }
                >
                    { value }
                </textarea>
                <div className={ styles.textareaPlaceholder }>{ placeholder }</div>
                <label htmlFor={ realId }>
                    <span className={ styles.textareaLabelText }>{ label }</span>
                    { required ? (<span className={ styles.textareaRequiredAsteriks }>&nbsp;*</span>) : null }
                </label>
            </div>

            { (clearable && !loading) && (
                <button
                    type='button'
                    className={ styles.textareaClear }
                    onClick={ handlerClearClick }
                    tabIndex={ -1 }
                >
                    <Icon name='xmark'/>
                </button>
            ) }

            { loading && (
                <Loader
                    className={ styles.textareaLoader }
                    size='tiny'
                    inverted
                />
            ) }

            { (error && (typeof error === 'string' || error.message)) && (
                <ErrorText
                    text={ typeof error === 'string' ? error : error.message }
                />
            ) }
        </div>
    );
};


export const Textarea: TTextareaElement = TextareaElement as TTextareaElement;