import React, { MouseEventHandler, useCallback } from 'react';
import cn from 'classnames';
import './style.scss';
import { TColor, TSize } from '@/types/common';
import { TIconName, TIconType } from '@/common/ui/icon/types';
import { Icon, Loader } from '@/common/ui';

type TIconButtonProps = React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> & {
    icon?: TIconName,
    iconType?: TIconType
    color?: TColor,
    size?: TSize,
    disabled?: boolean,
    loading?: boolean,
    className?: string,
    onClick?: MouseEventHandler<HTMLButtonElement>,
    ref?: React.Ref<HTMLButtonElement>,
}
export const IconButton: React.FC<TIconButtonProps> = React.forwardRef<HTMLButtonElement, TIconButtonProps>(function UIIconButton(
    {
        icon,
        disabled,
        color,
        size,
        className,
        iconType,
        loading,
        onClick,
        ...props
    },
    ref,
) {
    const handlerClick = useCallback<MouseEventHandler<HTMLButtonElement>>((e) => {
        if (!loading && !disabled) {
            onClick(e);
        }
    }, [ disabled, loading, onClick ]);

    return (
        <button
            ref={ ref }
            type='button'
            className={ cn('FUI-icon-button', className, { '-loading': loading, '-disabled': disabled }) }
            disabled={ disabled }
            onClick={ handlerClick }
            { ...props }
        >
            { loading && (
                <div className='fz9'>
                    <Loader size='tiny' inverted/>
                </div>
            ) }
            { !loading && (
                <Icon
                    type={ iconType || 'solid' }
                    name={ icon }
                    color={ color }
                    size={ size }
                />
            ) }
        </button>
    );
})
