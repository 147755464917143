import React, { useCallback, useState } from 'react';
import scrollToElement from 'scroll-to-element';
import { formatMoney } from '@/utils';
import { MExpensesTypes, MProofOfIncome, MProofOfJob } from '@/components/leads/components/credit-from/consts';
import { validateStep } from '@/components/leads/components/credit-from/validation';
import { TStepProps } from '@/components/leads/components/credit-from/types';
import { AddActiveButton } from '@/components/leads/components/credit-from/components/add-active-button';
import { CarActiveFrom } from '@/components/leads/components/credit-from/components/car-active-from';
import { RealtyActiveForm } from '@/components/leads/components/credit-from/components/realty-active-form';
import { BankDepositActiveForm } from '@/components/leads/components/credit-from/components/bank-deposit-active-form';
import { EActiveType, ECurrency } from '@/consts';
import { Grid } from '@/common/ui/grid';
import { Button, Dropdown, Input, InputSuggestions } from '@/common/ui';


export const FinancesStep: React.FC<TStepProps> = ({
    fields,
    formType,
    handlerChange,
    nextStep,
    prevStep,
}) => {
    const [ errors, setErrors ] = useState<any>({});

    const handlerLocalChange = useCallback(async (e, { name, value, checked }) => {
        handlerChange(e, {
            name,
            value: checked === undefined ? value : checked,
        });
        setErrors((c) => ({ ...c, [name]: null }));

        // if (name === 'finances.otherIncome' || name === 'finances.primeTimeJobWage' ||  name === 'finances.wage') {
        //     const sumParts = {
        //         'finances.otherIncome': fields.finances.otherIncome,
        //         'finances.primeTimeJobWage': fields.finances.primeTimeJobWage,
        //         'finances.wage': fields.finances.wage,
        //         [name]: value,
        //     };
        //     handlerChange(e, {
        //         name: 'finances.averageMonthlyIncome',
        //         value: Object.values(sumParts).filter((i) => i).reduce((res, i) => res + parseInt(i, 10), 0),
        //     });
        //     setErrors((c) => ({ ...c, 'finances.averageMonthlyIncome': null }));
        // }
    }, [ handlerChange ]);

    const handlerSubmit = useCallback((next = false) => () => {
        const { isValid, errors } = validateStep('finances', fields, formType);

        if (!isValid) {
            setErrors(errors);
            setTimeout(() => {
                scrollToElement('.FUI-input.-error', { align: 'top', offset: -124 });
            }, 100);
            return;
        }
        nextStep(next);
    }, [ nextStep, fields, formType ]);

    const handlerAddActive = (activeType: EActiveType) => {
        switch (activeType) {
            case EActiveType.CAR:
                handlerChange(null, {
                    name: 'actives.car',
                    value: {
                        brand: null,
                        model: null,
                        generation: null,
                        body: null,
                        year: null,
                        modification: null,
                        mileage: null,
                    },
                });
                break;
            case EActiveType.BANK_DEPOSIT:
                handlerChange(null, {
                    name: 'actives.bankDeposit',
                    value: {
                        bankName: null,
                        amount: null,
                        currency: ECurrency.RUB,
                    },
                });
                break;
            case EActiveType.REALTY:
                handlerChange(null, {
                    name: 'actives.realty',
                    value: [
                        ...(fields.actives.realty || []),
                        {
                            address: null,
                            area: null,
                        },
                    ],
                });
                break;
        }
        setTimeout(() => scrollToElement(`.active-${activeType}`, { align: 'top', offset: -124 }), 200);
    };

    const handlerRemoveActive = React.useCallback((type) => () => {
        handlerChange(null, {
            name: `actives.${type}`,
            value: null,
        });
    }, [ handlerChange ]);

    const handlerClickAddExpense = React.useCallback(() => {
        handlerChange(null, {
            name: `finances.expenses`,
            value: [
                ...(fields.finances.expenses || []),
                {
                    type: '',
                    amount: '',
                },
            ],
        });
    }, [ fields.finances.expenses, handlerChange ]);

    const handlerClickRemoveExpense = React.useCallback((index) => () => {
        handlerChange(null, {
            name: `finances.expenses`,
            value: fields.finances.expenses.filter((i, idx) => idx !== index),
        });
    }, [ fields.finances.expenses, handlerChange ]);

    const handlerExpenseChange = React.useCallback((index) => (e, { name, value }) => {
        handlerChange(null, {
            name: `finances.expenses.${ index }.${ name }`,
            value: value,
        });
    }, [ handlerChange ]);

    return (
        <Grid stackable>
            <Grid.Row columns={1}>
                <Grid.Col>
                    <h4>Доходы</h4>
                </Grid.Col>
            </Grid.Row>

            <Grid.Row columns={3}>
                <Grid.Col>
                    <Input
                        type='number'
                        label='Заработная плата на основном месте'
                        name='finances.wage'
                        error={errors['finances.wage']}
                        value={fields.finances.wage}
                        onChange={handlerLocalChange}
                        postfix='руб.'
                        renderValue={(value) => formatMoney(value, false)}
                        required
                    />
                </Grid.Col>
                <Grid.Col>
                    <Input
                        type='number'
                        label='Заработная плата по совместительству'
                        name='finances.primeTimeJobWage'
                        error={errors['finances.primeTimeJobWage']}
                        value={fields.finances.primeTimeJobWage}
                        onChange={handlerLocalChange}
                        postfix='руб.'
                        renderValue={(value) => formatMoney(value, false)}
                    />
                </Grid.Col>
                <Grid.Col>
                    <Input
                        type='number'
                        label='Иные доходы'
                        name='finances.otherIncome'
                        error={errors['finances.otherIncome']}
                        value={fields.finances.otherIncome}
                        onChange={handlerLocalChange}
                        postfix='руб.'
                        renderValue={(value) => formatMoney(value, false)}
                    />
                </Grid.Col>
                <Grid.Col>
                    <Input
                        type='number'
                        label='Среднемесячный доход за 12 месяцев'
                        name='finances.averageMonthlyIncome'
                        error={errors['finances.averageMonthlyIncome']}
                        value={fields.finances.averageMonthlyIncome}
                        onChange={handlerLocalChange}
                        postfix='руб.'
                        renderValue={(value) => formatMoney(value, false)}
                    />
                </Grid.Col>
                <Grid.Col>
                    <Dropdown
                        label='Форма подтверждения дохода'
                        name='finances.proofOfIncome'
                        error={errors['finances.proofOfIncome']}
                        value={fields.finances.proofOfIncome}
                        options={MProofOfIncome.map((item) => ({
                            text: item,
                            value: item,
                        }))}
                        onChange={handlerLocalChange}
                        required
                        filtering
                    />
                </Grid.Col>
                <Grid.Col>
                    <Dropdown
                        label='Вид подтверждения занятости'
                        name='finances.proofOfJob'
                        error={errors['finances.proofOfJob']}
                        value={fields.finances.proofOfJob}
                        options={MProofOfJob.map((item) => ({
                            text: item,
                            value: item,
                        }))}
                        onChange={handlerLocalChange}
                        required
                        filtering
                    />
                </Grid.Col>
            </Grid.Row>

            <Grid.Row columns={1}>
                <Grid.Col>
                    <h4>Расходы</h4>
                </Grid.Col>
            </Grid.Row>

            {fields.finances.expenses && fields.finances.expenses.map((expense, index) => (
                <Grid.Row columns={16} className='p0' key={index}>
                    <Grid.Col colspan={5}>
                        <InputSuggestions
                            type='text'
                            label='Вид расходов'
                            name='type'
                            error={errors[`finances.expenses.${ index }.type`]}
                            value={expense.type}
                            onChange={handlerExpenseChange(index)}
                            suggestions={MExpensesTypes.map((item) => ({
                                text: item,
                                value: item,
                            }))}
                            autoComplete='off'
                            required
                        />
                    </Grid.Col>
                    {expense.type === 'Кредитная нагрузка' && (
                        <Grid.Col colspan={5}>
                            <Input
                                type='number'
                                label='Сумма всех кредитов'
                                name='additionalAmount'
                                error={errors[`finances.expenses.${ index }.additionalAmount`]}
                                value={expense.additionalAmount}
                                onChange={handlerExpenseChange(index)}
                                postfix='руб.'
                                renderValue={(value) => formatMoney(value, false)}
                            />
                        </Grid.Col>
                    )}
                    <Grid.Col colspan={5}>
                        <Input
                            type='number'
                            label={ expense.type === 'Кредитная нагрузка' ? 'Сумма всех ежемесячный платежей' : 'Сумма расходов в мес' }
                            name='amount'
                            error={errors[`finances.expenses.${ index }.amount`]}
                            value={expense.amount}
                            onChange={handlerExpenseChange(index)}
                            postfix='руб.'
                            renderValue={(value) => formatMoney(value, false)}
                            required
                        />
                    </Grid.Col>
                    <Grid.Col colspan={1}>
                        <Button
                            className='mt-5'
                            icon='xmark'
                            onClick={handlerClickRemoveExpense}
                            color='white'
                        />
                    </Grid.Col>
                </Grid.Row>
            ))}

            <Grid.Row columns={1}>
                <Grid.Col>
                    <Button
                        icon='plus'
                        content='Добавить расход'
                        onClick={handlerClickAddExpense}
                    />
                </Grid.Col>
            </Grid.Row>

            <Grid.Row columns={1}>
                <Grid.Col>
                    <h4>Активы</h4>
                </Grid.Col>
            </Grid.Row>

            {fields.actives.car && (
                <Grid.Row columns={1}>
                    <Grid.Col>
                        <CarActiveFrom
                            fields={fields.actives.car}
                            onChange={handlerLocalChange}
                            onRemove={handlerRemoveActive(EActiveType.CAR)}
                        />
                    </Grid.Col>
                </Grid.Row>
            )}

            {fields.actives.realty && (
                <Grid.Row columns={1}>
                    <Grid.Col>
                        <RealtyActiveForm
                            fields={fields.actives.realty}
                            onChange={handlerLocalChange}
                            onRemove={handlerRemoveActive(EActiveType.REALTY)}
                            errors={{}}
                        />
                    </Grid.Col>
                </Grid.Row>
            )}

            {fields.actives.bankDeposit && (
                <Grid.Row columns={1}>
                    <Grid.Col>
                        <BankDepositActiveForm
                            fields={fields.actives.bankDeposit}
                            onChange={handlerLocalChange}
                            onRemove={handlerRemoveActive(EActiveType.BANK_DEPOSIT)}
                        />
                    </Grid.Col>
                </Grid.Row>
            )}

            {Object.keys(fields.actives).filter((i) => fields.actives[i] == null).length > 0 && (
                <Grid.Row columns={1}>
                    <Grid.Col>
                        <AddActiveButton
                            exists={Object.keys(fields.actives).filter((i) => fields.actives[i] !== null)}
                            onPick={handlerAddActive}
                        />
                    </Grid.Col>
                </Grid.Row>
            )}

            <Grid.Row
                columns={1}
                style={{
                    position: 'sticky',
                    bottom: 0,
                    zIndex: 2,
                    background: ' #fff',
                }}
            >
                <Grid.Col>
                    <Button
                        className='mr-5'
                        content='Сохранить и продолжить'
                        onClick={ handlerSubmit(true) }
                        primary
                    />
                    <Button
                        className='mr-5'
                        content='Сохранить'
                        onClick={ handlerSubmit() }
                        primary
                    />
                    <Button
                        content='Отмена'
                        onClick={ prevStep }
                    />
                </Grid.Col>
            </Grid.Row>
        </Grid>
    );
}
