import { Extension, Root } from 'joi';
import { Validator } from 'finbox-ui-kit/libs/validator';


export const JoiSnilsExtension = (joi: Root): Extension => ({
    type: 'snils',
    base: joi.string(),
    messages: {
        'snils.format': 'Некорректный формат',
    },
    coerce(value: string) {
        return { value: value?.trim() };
    },
    validate(value, helpers) {
        const isValid = Validator.snils(value);
        if (isValid !== true) {
            return { value, errors: helpers.error('snils.format') };
        }
        return { value };
    },
});
