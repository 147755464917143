import React, { useCallback, useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import { Timer } from '@/common/time-tracker/timer';
import { Storage } from '@/utils/storage';
import { SOCKET_EVENT } from '@/consts';
import { useConfigController } from '@/controllers/config.controller';
import { useApiClient } from '@/libs/api-client/use-api-client';
import { useSocketsContext } from '@/libs/sockets/sockets.context';
import { API_PROFILE_ACTIVITY_PAUSE, API_PROFILE_ACTIVITY_RESUME } from '@/common/time-tracker/_consts';
import { Icon, Loader } from '@/common/ui';
import styles from './time-tracker.module.scss';

const STORAGE_KEY = 'INACTIVE_TIME';


export const TimeTracker = () => {
    const { isLoadingConfig, config } = useConfigController();
    const { subscribe, unsubscribe } = useSocketsContext();
    const [ paused, setPaused ] = useState(false);

    useEffect(() => {
        if (config) {
            setPaused(config.timeTracker.paused);
            if (config.timeTracker.paused) {
                Storage.setItem(STORAGE_KEY, config.timeTracker.timestamp);
            }
        }
    }, [ config ]);

    const { loading: isLoadingFetch, fetch } = useApiClient({
        method: 'post',
    });

    const loading = isLoadingFetch || isLoadingConfig;

    const handlerPauseOnEvent = useCallback((data) => {
        setPaused(true);
        Storage.setItem(STORAGE_KEY, data.timestamp);
    }, []);

    const handlerPauseOffEvent = useCallback(() => {
        setPaused(false);
        Storage.removeItem(STORAGE_KEY);
    }, []);

    useEffect(() => {
        // TODO: переделать но сокеты в связке с беком
        subscribe(SOCKET_EVENT.PAUSE_ON, handlerPauseOnEvent);
        subscribe(SOCKET_EVENT.PAUSE_OFF, handlerPauseOffEvent);
        return () => {
            unsubscribe(SOCKET_EVENT.PAUSE_ON, handlerPauseOnEvent);
            unsubscribe(SOCKET_EVENT.PAUSE_OFF, handlerPauseOffEvent);
        };
    }, [ handlerPauseOffEvent, handlerPauseOnEvent, subscribe, unsubscribe ]);


    const handlerClickPause = useCallback(async () => {
        await fetch(null, {
            url: API_PROFILE_ACTIVITY_PAUSE,
        });
    }, [ fetch ]);

    const handlerClickResume = useCallback(async () => {
        await fetch(null, {
            url: API_PROFILE_ACTIVITY_RESUME,
        });
    }, [ fetch ]);

    return (
        <div className={ styles.timeTracker }>
            {loading && (
                <Loader />
            )}
            { !loading && !paused && (
                <button
                    className={ styles.timeTrackerButton }
                    onClick={ handlerClickPause }
                >
                    <Icon type='solid' name='pause' color='white' className='mr-5'/>
                    Перерыв
                </button>
            ) }
            { !loading && paused && (
                <button
                    className={ styles.timeTrackerButton }
                    onClick={ handlerClickResume }
                >
                    <Icon name='play' color='white' className='mr-5'/>
                    <Timer
                        isActive
                        startTime={ (DateTime.now().toMillis() - Storage.getItem(STORAGE_KEY)) / 1000 }
                        isHoursShowed
                    />
                </button>
            ) }
        </div>
    );
};

export default TimeTracker;
