import { Controller, FieldValues } from 'react-hook-form';
import React from 'react';
import { Textarea } from './textarea';
import { TextareaControlledProps } from './textarea.types';


export function TextareaControlled<TFieldValues extends FieldValues = FieldValues>({ control, ...textareaProps }: TextareaControlledProps<TFieldValues>) {
    return (
        <Controller
            control={ control }
            name={ textareaProps.name }
            render={ ({ field }) => (
                <Textarea
                    { ...textareaProps }
                    { ...field }
                    ref={ (current) => {
                        field.ref(current);
                        if (typeof textareaProps.ref === 'function') {
                            textareaProps.ref(current)
                        } else if (textareaProps.ref) {
                            textareaProps.ref.current = current;
                        }
                    } }
                    error={ textareaProps.error?.message }
                />
            ) }
        />
    );
}