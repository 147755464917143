import React, { useMemo } from 'react';
import { copyBlobToClipboard } from 'copy-image-clipboard';
import { toast } from 'react-toastify';
import { formatInitialsShort } from 'finbox-ui-kit/utils/format-initials';
import { useDownloadFile } from '@/utils/hooks/use-download-file';
import { API_BASE_URL } from '@/consts';
import { formatMoney } from '@/utils';
import { ERequisitesVariant, requisites } from '@/components/leads/components/requisites/const';
import { Table } from '@/common/ui/table';
import { calculateNds } from '@/utils/calculate-nds';
import { Button, Grid, Loader } from '@/common/ui';

export const RequisitesView = ({ type, contractId, contractDate, contractSum, clientInitials }) => {
    const { isLoading, download } = useDownloadFile();
    const [ QRCode, setQRCode ] = React.useState<Blob>();

    const nds = useMemo(() => calculateNds(contractSum), [ contractSum ]);

    React.useEffect(() => {
        download(
            `${ API_BASE_URL }/requisites/qr-code/?${ new URLSearchParams({
                type,
                contractId,
                contractDate,
                contractSum: formatMoney(nds.amount),
                commissionNDSAmount: formatMoney(nds.NDSAmount),
                clientInitials: formatInitialsShort(clientInitials),
            }) }`,
            false,
            true,
            true,
        ).then(setQRCode);
    }, [ download, type, contractId, contractDate, clientInitials, nds ]);


    const handlerClickCopyQRCode = React.useCallback(async (e) => {
        e.preventDefault();
        await copyBlobToClipboard(QRCode);
        toast.info('QR-code скопирован в буфер обмена');
    }, [ QRCode ]);

    return (
        <Grid>
            <Grid.Row columns={ 16 }>
                <Grid.Col colspan={ 11 }>
                    <Table>
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell>Наименование организации:</Table.Cell>
                                <Table.Cell>{ requisites.get(type).name }</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>ИНН организации:</Table.Cell>
                                <Table.Cell>{ requisites.get(type).inn }</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>КПП организации:</Table.Cell>
                                <Table.Cell>{ requisites.get(type).kpp || '-' }</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>
                                    { type === ERequisitesVariant.LEGAL && 'ОГРН организации:' }
                                    { type === ERequisitesVariant.INDIVIDUAL && 'ОГРНИП организации:' }
                                </Table.Cell>
                                <Table.Cell>{ requisites.get(type).ogrn || '-' }</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>Номер расчётного счёта:</Table.Cell>
                                <Table.Cell>{ requisites.get(type).account }</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>Корр. счёт банка:</Table.Cell>
                                <Table.Cell>{ requisites.get(type).corAccount }</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>Банк:</Table.Cell>
                                <Table.Cell>{ requisites.get(type).bankName }</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>БИК:</Table.Cell>
                                <Table.Cell>{ requisites.get(type).bankBIK }</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>Адрес:</Table.Cell>
                                <Table.Cell>{ requisites.get(type).address }</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>Назначение платежа:</Table.Cell>
                                <Table.Cell>
                                    Оплата по договору №{ contractId } от { contractDate }, { formatInitialsShort(clientInitials) } в размере { formatMoney(nds.amount) }{ ' ' }
                                    { requisites.get(type).withNDS ? `в том числе НДС 20% ${ formatMoney(nds.NDSAmount) }` : 'НДС не облагается' }
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>Сумма:</Table.Cell>
                                <Table.Cell>{ formatMoney(nds.amount) } { requisites.get(type).withNDS ? `в том числе НДС 20% ${ formatMoney(nds.NDSAmount) }` : 'НДС не облагается' }</Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                </Grid.Col>
                <Grid.Col colspan={ 5 }>
                    { isLoading && (
                        <Loader inverted/>
                    ) }
                    { (!isLoading && QRCode) && (
                        <div>
                            <img src={ window.URL.createObjectURL(QRCode) } alt='QR code'/>
                        </div>
                    ) }
                    <Button
                        className='mt-5'
                        icon='copy'
                        content='Копировать QR-code'
                        onClick={ handlerClickCopyQRCode }
                        size='small'
                    />
                </Grid.Col>
            </Grid.Row>
        </Grid>
    );
};
