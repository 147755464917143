import { addressScheme } from '@/components/leads/components/credit-from/schemas/address.scheme';
import { TPassport } from '@/components/leads/components/credit-from/types';
import { EClientAddressBasis } from '@/consts';
import { Joi } from '@/libs/joi';

export const passportScheme = Joi.object<TPassport>({
    birthday: Joi.string().required(),
    birthplace: Joi.string().required(),
    serialAndNumber: Joi.string().required(),
    dateOfIssue: Joi.string().required(),
    departmentCode: Joi.string().required(),
    department: Joi.string().required(),
    address: addressScheme.required(),
    registrationAddress: addressScheme.required(),
    registrationDate: Joi.string().required(),
    addressEqualRegistration: Joi.boolean().default(false),
    addressBasis: Joi.string().allow(...Object.values(EClientAddressBasis)).required(),
    snils: Joi.snils(),
});