import React, { MouseEventHandler } from 'react';
import cn from 'classnames';
import { ButtonsGroup } from '@/common/ui/button/buttons-group';
import { Icon } from '@/common/ui';
import { TColor, TSize } from '@/types/common';
import { TIconBrandName, TIconName, TIconType } from '@/common/ui/icon/types';
import { Loader } from '../loader';
import styles from './button.module.scss';

const colorsMap: Record<TColor, string> = {
    white: styles.buttonWhite,
    red: styles.buttonRed,
    orange: styles.buttonOrange,
    yellow: styles.buttonYellow,
    olive: styles.buttonOlive,
    green: styles.buttonGreen,
    teal: styles.buttonTeal,
    blue: styles.buttonBlue,
    violet: styles.buttonViolet,
    purple: styles.buttonPurple,
    pink: styles.buttonPink,
    brown: styles.buttonBrown,
    grey: styles.buttonGrey,
    black: styles.buttonBlack,
};

export type ButtonOwnProps<E extends React.ElementType = React.ElementType> = {
    as?: E
    content?: string
    children?: React.ReactNode
    onClick?: MouseEventHandler<E> | undefined
    className?: string
    icon?: TIconName | TIconBrandName
    iconType?: TIconType
    iconColor?: TColor
    color?: TColor
    size?: TSize
    compact?: boolean
    primary?: boolean
    disabled?: boolean
    loading?: boolean
    fluid?: boolean
};

export type ButtonProps<E extends React.ElementType> = ButtonOwnProps<E> &
    Omit<React.ComponentProps<E>, keyof ButtonOwnProps>;

const defaultElement = "button";

type TButton = (<E extends React.ElementType = typeof defaultElement>(
    props: ButtonProps<E>,
) => React.ReactElement | null) & {
    Group?: any
}

//@ts-expect-error
export const Button: TButton = React.forwardRef(function Button(
    {
        as,
        content,
        children,
        onClick,
        className,
        color,
        primary,
        disabled,
        loading,
        icon,
        iconType = 'light',
        iconColor,
        size,
        compact,
        fluid,
        ...props
    }: ButtonOwnProps,
    ref: React.Ref<Element>,
) {
    const ButtonAs = as || defaultElement;
    const handlerClick = (e) => {
        if (onClick && !loading) {
            onClick(e);
        }
    };

    return (
        <ButtonAs
            ref={ ref }
            onClick={ handlerClick }
            disabled={ disabled }
            className={ cn(
                styles.button,
                primary ? styles.buttonPrimary : color && colorsMap[color],
                className,
                {
                    [styles.buttonLoading]: loading,
                    [styles.buttonWithIcon]: icon,
                    [styles.buttonOlnyIcon]: !(content || children),
                    [styles.buttonCompact]: compact,
                    [styles.buttonFluid]: fluid,
                    [`-${ size }`]: size,
                },
            ) }
            { ...props }
        >
            { loading && (
                <div className={ styles.buttonLoader }>
                    <Loader size='tiny' inverted={ !primary && (!color || color === 'white') }/>
                </div>
            ) }
            <span style={ { ...(loading && { visibility: 'hidden' }) } }>
                { icon && (
                    <span className={ styles.buttonIcon }>
                        <Icon
                            name={ icon as any }
                            type={ iconType }
                            color={ iconColor }
                        />
                    </span>
                ) }
                { content || children }
            </span>
        </ButtonAs>
    );
});

Button.Group = ButtonsGroup;
