import React, { ChangeEvent } from 'react';
import { formatPhoneNumber } from '@/utils/formatter';

export const phonePasteHandler = (field: any) => {
    const onPaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
        event.preventDefault();
        const pasteData = event.clipboardData.getData("text");
        const digits = pasteData.match(/[0-9]+/g);
        if (!digits) {
            field.onChange({
                ...event,
                target: {
                    ...event.target,
                    value: null,
                },
            });
            return;
        }
        const clean = digits.join('');

        if (clean.length !== 11) {
            field.onChange({
                ...event,
                target: {
                    ...event.target,
                    value: formatPhoneNumber(clean),
                },
            });
            return;
        }
        field.onChange({
            ...event,
            target: {
                ...event.target,
                value: formatPhoneNumber(clean),
            },
        });
    }

    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        if (value === '+7 (7') {
            field.onChange({
                ...event,
                target: {
                    ...event.target,
                    value: null,
                },
            });
        } else {
            field.onChange({
                ...event,
                target: {
                    ...event.target,
                    value: value as string,
                },
            });
        }
    }

    return {
        onPaste,
        onChange,
    }
}
