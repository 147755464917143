import React from 'react';
import cn from 'classnames';
import { TGridRow } from './types';
import styles from './grid.module.scss';

export const GridRow: TGridRow = ({
    children,
    className,
    style,
    columns = 16,
    noRowGap,
    columnAlign,
    stretched,
    gap = 1,
}) => (
    <div
        className={ cn(styles.gridRow, className, {
            '-no-row-gap': noRowGap,
            [styles.gridRowStretched]: stretched,
        }) }
        style={ {
            // @ts-ignore
            '--row-columns': columns,
            '--row-gap': gap + 'em',
            ...columnAlign && { alignItems: columnAlign },
            ...style && style,
        } }
    >
        { children }
    </div>
);
