import React, { useCallback, useEffect, useState } from 'react';

import { useNavigate } from 'react-router';
import { TContractTemplate } from '@/types/contract-template';
import { API_URLS } from '@/consts';
import { UIPreviewDocument } from '@/common/ui/preview-document';
import { ErrorsPopup } from '@/components/leads/components/documents/components/errors-popup';
import { NoOrdersPopup } from '@/components/leads/components/documents/components/no-orders-popup';
import { OrdersPickerPopup } from '@/components/leads/components/documents/components/orders-picker-popup';
import { ICreditorOrder } from '@/types/creaditors';
import { TClient } from '@/types/lead';
import { useValidateClientForDocument } from '@/components/leads/components/documents/use-validate-client-for-document';
import { useDictionary } from '@/utils/hooks/use-dictionary';
import { useApiClient } from '@/libs/api-client/use-api-client';
import { useModal } from '@/common/ui';


type TPaths = {
    preview: string,
    docx: string,
    pdf: string,
};

type TClientDocumentsProps = {
    open: boolean,
    client: TClient,
    document: TContractTemplate,
    onClose: () => void,
    onSuccess: () => void,
}
export const ClientDocuments = ({ open, client, document, onClose, onSuccess }: TClientDocumentsProps) => {
    const navigate = useNavigate();
    const [ paths, setPaths ] = useState<TPaths>(null);
    const [ noOrdersError, setNoOrdersError ] = useState(false);
    const [ orders, setOrders ] = useState<ICreditorOrder[]>(null);
    const [ selectedDocument, setSelectedDocument ] = useState<TContractTemplate>(null);
    const [ previewItem, setPreviewItem ] = useState<TContractTemplate | null>(null);
    const { show, props } = useModal({
        onClose: () => {
            setPreviewItem(null);
        },
    });

    const [ documents ] = useDictionary('documents', []);
    const { validate, errors, reset } = useValidateClientForDocument({ clientId: client?.id });

    const { fetch } = useApiClient({
        method: 'post',
        url: `${ API_URLS.CONTRACT_TEMPLATES.BASE }contract-for-client/${ client?.id }/`,
    });

    const { fetch: fetchOrders } = useApiClient({
        url: `${ API_URLS.CONTRACT_TEMPLATES.ORDERS_FOR_CONTRACT }${ client?.id }/`,
    });

    const generateDocument = useCallback(async (doc: TContractTemplate, order: ICreditorOrder) => {
        const response = await fetch({
            templateId: doc.id,
            orderId: order.id,
        });
        setPaths(response);
        setPreviewItem(doc);
        show();
    }, [ fetch, show ]);

    const download = useCallback(async () => {
        const isValid = await validate();

        if (!isValid) {
            return;
        }

        const { data: orders } = await fetchOrders();

        if (!orders?.length) {
            setNoOrdersError(true);
            onSuccess()
            return;
        } else if (orders.length > 1) {
            setOrders(orders);
            setSelectedDocument(document);
            // onSuccess()
            return;
        }
        // toast.info('Файл скачивается');
        await generateDocument(document, orders[0]);
        onSuccess()
    }, [ document, fetchOrders, generateDocument, onSuccess, validate ]);

    useEffect(() => {
        if (open) {
            download();
        }
    }, [ download, open ]);

    const handlerCloseErrors = useCallback(() => {
        onClose();
        reset();
    }, [ onClose, reset ]);

    const handlerCloseNoOrdersPopup = useCallback(() => {
        onClose();
        setNoOrdersError(false);
    }, [ onClose ]);

    const handlerCloseOrdersPickerPopup = useCallback(() => {
        onClose();
        setOrders(null);
    }, [ onClose ]);

    const handlerPickOrder = useCallback(async (order: ICreditorOrder) => {
        setOrders(null);
        await generateDocument(selectedDocument, order);
    }, [ generateDocument, selectedDocument ]);

    const handlerClickEdit = (tab: string) => {
        navigate(`/leads/${client.id}/profile/edit/${tab}/`);
        reset();
        onClose();
    }
    if (documents.length === 0) {
        return null;
    }

    return (
        <>
            <UIPreviewDocument
                { ...props }
                title={ previewItem ? `${ previewItem.name } (${ previewItem.originFileName })` : '' }
                url={ paths && paths.preview }
                downloadWordLink={ paths && paths.docx }
                downloadWordPDFLink={ paths && paths.pdf }
            />
            { errors.length > 0 && (
                <ErrorsPopup
                    errors={ errors }
                    open
                    onClose={ handlerCloseErrors }
                    onClickEdit={ handlerClickEdit }
                />
            ) }
            <NoOrdersPopup
                open={ noOrdersError }
                onClose={ handlerCloseNoOrdersPopup }
            />
            <OrdersPickerPopup
                orders={ orders }
                open={ !!orders }
                onClose={ handlerCloseOrdersPickerPopup }
                onPickOrder={ handlerPickOrder }
            />
        </>
    );
};
