import React, { useCallback, useImperativeHandle, useMemo, useRef, useState } from 'react';
import cn from 'classnames';
import { UIPopup } from 'finbox-ui-kit';
import { useEventListener } from 'finbox-ui-kit/utils/hooks/use-event-listener';
import { extractString } from 'finbox-ui-kit/utils';
import { OptionsList, TOption } from '@/common/ui/options-list';
import { TInputChangeData } from '@/common/ui/input/input.types';
import { Input } from '@/common/ui/input';
import { Icon } from '@/common/ui/icon';
import { ErrorText } from '@/common/ui/error-text';
import { DropdownProps, TDropdownElement } from '@/common/ui/dropdown/dropdown.types';
import { useModal } from '@/common/ui/modal';
import { isDefined } from '@/utils/is-defined';
import styles from './dropdown.module.scss';

function DropdownElement(
    {
        name,
        label,
        value,
        options,
        onChange,
        disabled,
        loading,
        readOnly,
        error,
        clearable,
        filtering,
        filteringInclude,
        onChangeFilter,
        placeholder,
        size,
        required,
        className,
        multiple,
        text,
        autoComplete,
    }, forwardRef,
) {
    const dropdownRef = useRef<HTMLDivElement>(null);
    const selectRef = useRef<HTMLSelectElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const [ filter, setFilter ] = useState(null);
    const { show, hide, props } = useModal({
        hideScroll: false,
        onClose() {
            setFilter(null);
            inputRef.current?.blur();
        },
    });

    useImperativeHandle<HTMLSelectElement | null, any>(forwardRef, () => ({
        focus() {
            inputRef.current?.focus();
        },
    }), []);

    const handlerKeydown = useCallback((e) => {
        if (e.key === 'Tab' && props.open) {
            hide();
        }
    }, [ hide, props.open ]);


    useEventListener('keydown', handlerKeydown);

    const _value = useMemo(() => {
        if (!multiple) {
            return value;
        }
        if (!isDefined(value)) {
            return null;
        }
        if (!Array.isArray(value)) {
            return [ value ];
        }
        return value;
    }, [ multiple, value ]);


    function handlerSelectOption(option: TOption) {
        if (multiple) {
            if (_value?.includes(option.value)) {
                const _values = value.filter((v) => v !== option.value);
                onChange(_values.length > 0 ? _values : null, {
                    name,
                    value: _values.length > 0 ? _values : null,
                    option,
                });
            } else {
                onChange([ ...(_value || []), option.value ], {
                    name,
                    value: [ ...(_value || []), option.value ],
                    option,
                });
            }
            return;
        }

        onChange(option.value, { name, value: option.value, option });
        hide();
    }

    function handlerChangeInput(_: any, { value }: TInputChangeData<any>) {
        if (!props.open && value === null) {
            onChange(null, {
                name,
                value: null,
                option: { value: null } as TOption,
            });
            hide();
        }
        if (filtering) {
            setFilter(value);
            onChangeFilter && onChangeFilter(value);
        }
    }

    function showOptions() {
        show()
    }

    const valueText: React.ReactNode | React.ReactNode[] = useMemo(() => {
        if (text) {
            return text;
        }
        if (multiple) {
            return options.filter((option) => _value?.includes(option.value))?.map((option, index) => (
                <span key={ index + option.value }>{ index !== 0 && ', ' }{ option.text }</span>
            ))
        }
        return options.find((i) => i.value === _value)?.text;
    }, [ _value, multiple, options, text ]);

    const _options = (
        !filtering
            ? options
            : options.filter((option) => {
                if (!filter) {
                    return true;
                }
                if (filteringInclude) {
                    return (extractString(option.text) || '').toLowerCase().includes(filter.toLowerCase());
                }
                return (extractString(option.text) || '').toLowerCase().startsWith(filter.toLowerCase());
            })
    ).map((option) => ({
        ...option,
        picked: multiple ? _value?.includes(option.value) : option.value === _value,
    }));

    const _error = useMemo(() => {
        if (!error) {
            return null;
        }
        if (typeof error === 'string') {
            return error;
        }
        if (Array.isArray(error)) {
            return error?.[0]?.message;
        }
        return error.message;
    }, [ error ]);

    return (
        <div className={ cn(styles.dropdown, className, {
            [styles.dropdownOpened]: props.open,
            [styles.dropdownOpened]: props.open,
            [`-${ size }`]: size,
        }) }>
            <div ref={ dropdownRef } className={ styles.dropdownInput }>
                <select
                    ref={ selectRef }
                    value={ _value || (multiple ? [] : '') }
                    name={ name }
                    tabIndex={ -1 }
                    multiple={ multiple }
                    onChange={ () => null }
                    onFocus={ () => inputRef.current?.focus() }
                ></select>
                <Input
                    ref={ inputRef }
                    name={ name }
                    label={ label }
                    value={ filter }
                    onChange={ handlerChangeInput }
                    disabled={ disabled }
                    loading={ loading }
                    error={ !!error }
                    clearable={ !props.open && clearable }
                    readOnly={ readOnly || !filtering }
                    autoComplete={ autoComplete || 'off' }
                    placeholder={ placeholder }
                    required={ required }
                    size={ size }
                    onFocus={ () => showOptions() }
                    onClick={ () => showOptions() }
                    active={ Array.isArray(valueText) ? valueText.length > 0 : !!valueText }
                    role='presentation'
                    allowClearOnReadOnly
                />
                { !(props.open && filtering) && (
                    <div className={ styles.dropdownValue }>{ valueText }</div>
                ) }

                { (!readOnly && (!isDefined(_value) || value === '' || !clearable) || props.open) && (
                    <Icon className={ styles.dropdownCaret } name='chevron-down'/>
                ) }
            </div>
            <UIPopup
                targetRef={ dropdownRef }
                open={ props.open }
                onClickOutside={ () => hide() }
                onClose={ () => hide() }
                minWidth='100%'
                maxWidth={ 600 }
            >
                <OptionsList
                    active={ props.open }
                    options={ _options }
                    onSelect={ handlerSelectOption }
                    fluid
                />
            </UIPopup>

            { (_error) && (
                <ErrorText text={ _error }/>
            ) }
        </div>
    );
}


export const Dropdown = React.forwardRef<HTMLSelectElement, DropdownProps>(DropdownElement) as TDropdownElement;