import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import cn from 'classnames';
import { Button } from '@/common/ui';
import { FilePickerProps, TFilePickerElement } from './types';
import styles from './file-picker.module.scss';


export const FilePicker: TFilePickerElement = React.forwardRef<HTMLDivElement, FilePickerProps>(function FilePicker({
    accept,
    minSize,
    maxSize,
    maxFiles,
    onChange,
    disabled,
    loading,
    fluid,
    error,
    required,
    buttonText = 'Прикрепить файлы',
    value = [],
}, ref) {
    const handlerPickFiles = useCallback(async (acceptedFiles: File[]) => {
        onChange([ ...value, ...acceptedFiles ]);
    }, [ onChange, value ]);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        accept,
        minSize,
        maxSize,
        maxFiles,
        onDrop: handlerPickFiles,
        disabled: loading || disabled || disabled || value.length === maxFiles,
    });

    return (
        <div
            ref={ ref }
            className={ cn(styles.filePicker, {
                [styles.filePickerDisabled]: disabled,
                [styles.filePickerFuild]: fluid,
            }) }
        >
            <div
                { ...getRootProps() }
                className={ cn(
                    styles.filePickerDropZone,
                    { [styles.filePickerDropZoneDragActive]: isDragActive },
                ) }
            >
                <input { ...getInputProps() } />
                <Button
                    as='label'
                    htmlFor='attachment'
                    icon='paperclip'
                    loading={ loading }
                    disabled={ disabled || value.length === maxFiles }
                >
                    { buttonText }
                    { required ? (<span className='color-red'>&nbsp;*</span>) : null }
                </Button>
                { !Array.isArray(error) && error?.message && (
                    <div className='mt-5 fz11 color-red'>{ error?.message }</div>
                ) }
                <div className='mt-5 fz11 color-grayDarken'>Вы можете добавить файл перетащив его на кнопку</div>
            </div>
        </div>
    );
});