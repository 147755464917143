import React from 'react';
import { ProgramSelectionErrors } from '@/components/leads/components/order-selection/components/program-selection-errors';
import { Button, Modal } from '@/common/ui';

type TErrorsPopupProps = {
    open: boolean,
    onClose: () => void,
    onClickEdit: (tab: string) => void,
    errors: any[],
    joiErrors?: boolean,
}
export const ErrorsPopup: React.FC<TErrorsPopupProps> = ({ open, onClose, errors, onClickEdit, joiErrors }) => (
    <Modal open={ open } onClose={ onClose } size='small'>
        <Modal.Header>Ошибки заполнения анкеты</Modal.Header>
        <Modal.Content>
            <ProgramSelectionErrors
                text='Для формирования документа необходимо исправить ошибки'
                errors={ errors }
                onClickEdit={ onClickEdit }
                joiErrors={joiErrors}
            />
        </Modal.Content>
        <Modal.Actions className='text-left'>
            <Button
                content='Закрыть'
                onClick={ onClose }
            />
        </Modal.Actions>
    </Modal>
);
