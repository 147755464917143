import React, { useCallback, useEffect, useState } from 'react';
import { cloneDeep, merge, set } from 'lodash';
import { useNavigate } from 'react-router';
import { Route, Routes, useMatch } from 'react-router-dom';
import scrollToElement from 'scroll-to-element';
import { PersonalStep } from '@/components/leads/components/credit-from/contains/personal-step';
import { WorkAndEducationStep } from '@/components/leads/components/credit-from/contains/work-and-education-step';
import { FinancesStep } from '@/components/leads/components/credit-from/contains/finances-step';
import { ECreditFormType } from '@/components/leads/components/credit-from/consts';
import { ClientStep } from '@/components/leads/components/credit-from/contains/client-step';
import { PledgeObjectStep } from '@/components/leads/components/credit-from/contains/pledge-object-step';
import { ThirdPartyPersonsStep } from '@/components/leads/components/credit-from/contains/third-party-persons-step';

import './style.scss';
import { useQueryString } from '@/utils/hooks/use-query-string';
import { Block, Loader } from '@/common/ui';


export const CLIENT_INITIAL_FIELDS = {
    client: {
        initials: {
            name: null,
            surname: null,
            patronymic: null,
        },
        whatsapp: null,
        manager: null,
        comment: null,
        products: [],
        sum: null,
        period: null,
        phone: null,
    },
    credit: {
        purpose: null,
        estateType: null,
        estatePhase: null,
        loanSecurity: null,
        subsidies: null,
        initialPaymentSum: null,
        initialPaymentInclude: null,
        insurance: null,
        insuranceTypes: {
            objectOfPledge: false,
            lifeAndWorkCapacity: false,
            titleOfProperty: false,
            collective: false,
        },
    },
    personal: {
        initialsChanged: false,
        oldInitials: {
            name: null,
            surname: null,
            patronymic: null,
        },
        birthday: null,
        address: null,
        addressEqualRegistration: false,
        passport: {
            serialAndNumber: null,
            dateOfIssue: null,
            departmentCode: null,
            department: null,
            registrationDate: null,
            registrationAddress: null,
        },
        snils: null,
    },
    family: {
        status: null,
        cohabitation: null,
        contract: null,
        amountOfMinorChildren: null,
        pairData: {
            initials: {
                name: '',
                surname: '',
                patronymic: '',
            },
            birthday: null,
            phone: null,
        },
        children: [],
    },
    workAndEducation: {
        education: null,
        employmentType: null,
        selfEmployedType: null,
        selfEmployedINN: null,
        company: {
            activityType: null,
            name: null,
            scope: null,
            phone: null,
            inn: null,
            ogrn: null,
            address: null,
        },
        employee: {
            position: null,
            startDate: null,
            seniority: null,
        },
        primeTimeJob: null,
    },
    finances: {
        averageMonthlyIncome: null,
        wage: null,
        primeTimeJobWage: null,
        otherIncome: null,
        proofOfIncome: null,
        proofOfJob: null,
        expenses: [],
    },
    actives: {
        car: null,
        bankDeposit: null,
        realty: null,
    },
    pledgeObjects: [
        {
            address: null,
            area: null,
            rights: [],
            encumbrances: [],
            documents: [],
        },
    ],
    borrowers: [],
    contacts: [],
};

export const CreditFrom = ({
    fields: existFields,
    baseUrl,
    onSubmit,
    onCancel,
    user,
    steps,
    loading,
}) => {
    const navigate = useNavigate();
    const { phone } = useQueryString();

    const [ fields, setFields ] = useState(cloneDeep(CLIENT_INITIAL_FIELDS));
    const match = useMatch('/leads/:id/profile/edit/:stepCode');
    const { params: { stepCode } } = match ? match : { params: { stepCode: undefined } };
    const currentStep = steps.find((i) => i.code === stepCode) || steps[0];

    useEffect(() => {
        if (existFields && Object.keys(existFields).length > 0) {
            setFields(merge(cloneDeep(CLIENT_INITIAL_FIELDS), existFields));
        }
    }, [ existFields ]);

    useEffect(() => {
        setFields((c) => ({
            ...c,
            client: {
                ...c.client,
                phone: phone || c.client.phone,
            },
        }));
    }, [ phone ]);

    const handlerChange = React.useCallback((e, { name, value, checked }) => {
        const updatedFields = set({
            ...fields,
        }, name, checked !== undefined ? checked : value);
        setFields(updatedFields);
    }, [ fields ]);

    const handlerNextStep = useCallback((step) => async (next, needFetchUpdate = true) => {
        await onSubmit(fields, !next, needFetchUpdate);
        if (next) {
            scrollToElement('body');
            const index = steps.indexOf(step);
            const nextStep = steps[index + 1];
            navigate(`${ baseUrl }${ nextStep.code }/`);
        }
    }, [ onSubmit, fields, steps, navigate, baseUrl ]);

    const handlerClickCancel = useCallback(() => {
        onCancel();
    }, [ onCancel ]);

    const formType = React.useMemo<ECreditFormType>(() => {
        if (!fields?.client?.products) {
            return null;
        }
        if (fields.client.products.includes(58)) {
            return ECreditFormType.MORTGAGE;
        } else if (fields.client.products.includes(1)) {
            return ECreditFormType.CREDIT;
        }
        return ECreditFormType.LOAN;
    }, [ fields ]);

    if (loading) {
        return (
            <div className='text-center mt2'>
                <Loader inverted/>
            </div>
        );
    }

    return (
        <div className='credit-form'>
            <div className='credit-form-content'>
                <Block title={ currentStep.title }>
                    <Routes>
                        { steps.map((step) => (
                            <Route key={ step.code } path={ step.code } element={ (
                                <>
                                    { step.code === 'client' && (
                                        <ClientStep
                                            formType={ formType }
                                            fields={ fields }
                                            handlerChange={ handlerChange }
                                            nextStep={ handlerNextStep(step) }
                                            prevStep={ handlerClickCancel }
                                            user={ user }
                                        />
                                    ) }
                                    { step.code === 'pledgeObjects' && (
                                        <PledgeObjectStep
                                            formType={ formType }
                                            fields={ fields }
                                            handlerChange={ handlerChange }
                                            nextStep={ handlerNextStep(step) }
                                            prevStep={ handlerClickCancel }
                                            user={ user }
                                        />
                                    ) }
                                    { step.code === 'personal' && (
                                        <PersonalStep
                                            formType={ formType }
                                            fields={ fields }
                                            handlerChange={ handlerChange }
                                            nextStep={ handlerNextStep(step) }
                                            prevStep={ handlerClickCancel }
                                            user={ user }
                                        />
                                    ) }
                                    { step.code === 'workAndEducation' && (
                                        <WorkAndEducationStep
                                            formType={ formType }
                                            fields={ fields }
                                            handlerChange={ handlerChange }
                                            nextStep={ handlerNextStep(step) }
                                            prevStep={ handlerClickCancel }
                                            user={ user }
                                        />
                                    ) }
                                    { step.code === 'finances' && (
                                        <FinancesStep
                                            formType={ formType }
                                            fields={ fields }
                                            handlerChange={ handlerChange }
                                            nextStep={ handlerNextStep(step) }
                                            prevStep={ handlerClickCancel }
                                            user={ user }
                                        />
                                    ) }
                                    { step.code === 'third-party-persons' && (
                                        <ThirdPartyPersonsStep
                                            formType={ formType }
                                            fields={ fields }
                                            handlerChange={ handlerChange }
                                            nextStep={ handlerNextStep(step) }
                                            prevStep={ handlerClickCancel }
                                            user={ user }
                                        />
                                    ) }
                                </>
                            ) }/>
                        )) }
                    </Routes>
                </Block>
            </div>
        </div>
    );
};

export default CreditFrom;
