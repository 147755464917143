import React from 'react';
import cn from 'classnames';
import { Loader } from '@/common/ui';
import styles from './app-loading-overlay.module.scss';

type AppLoadingOverlayProps = {
    visible: boolean;
};

export function AppLoadingOverlay({
    visible,
}: AppLoadingOverlayProps): React.ReactElement {
    return (
        <div
            className={ cn(styles.appLoadingOverlay, {
                [styles.appLoadingOverlayVisible]: visible,
            }) }>
            <Loader/>
        </div>
    );
}