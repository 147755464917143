import React, { useRef } from 'react';
import { UIPopup } from 'finbox-ui-kit';
import { TUIOptionsSelectHandler } from 'finbox-ui-kit/components/options-list/options';
import { useToggle } from 'finbox-ui-kit/utils/hooks';
import { OptionsList } from '@/common/options-list';
import { Button } from '@/common/ui';
import { TPageMenuItem } from '@/common/ui/page/page';

const mapItemsToOptions = (items: TPageMenuItem[]) => items.map((item): any => ({
    text: item.text,
    value: item.text,
    data: item,
    disabled: item.disabled,
    color: item.color,
    items: item.items && mapItemsToOptions(item.items),
}))

type TPageMenuProps = {
    menu: TPageMenuItem[];
}
export const PageMenu = ({ menu }: TPageMenuProps) => {
    const { on, toggle } = useToggle();
    const buttonRef = useRef<HTMLButtonElement | null>(null);

    const closeMenu = () => {
        toggle(false);
    }

    const handlerClickButton = () => {
        toggle();
    }

    const handlerSelectItem: TUIOptionsSelectHandler = (item) => {
        item.data.onClick();
        toggle(false);
    }

    return (
        <UIPopup.Wrapper className='ui-page-menu'>
            <Button
                ref={buttonRef}
                onClick={handlerClickButton}
                icon='ellipsis-vertical'
                iconType='regular'
            />

            <UIPopup
                targetRef={buttonRef}
                open={on}
                onClickOutside={closeMenu}
                position='bottom right'
                minWidth={0}
            >
                <OptionsList
                    options={mapItemsToOptions(menu)}
                    onSelect={handlerSelectItem}
                    itemsPosition='left top'
                />
            </UIPopup>
        </UIPopup.Wrapper>
    );
}