import React from 'react';
import cn from 'classnames';
import { TColor, TSize } from '@/types/common';
import styles from './divider.module.scss';


type TDividerProps = {
    content?: string;
    className?: string;
    children?: React.ReactNode
    size?: TSize
    color?: TColor
}

export const Divider: React.FC<TDividerProps> = ({ content, children, className, size, color }) => (
    <div className={ cn(
        styles.divider,
        className,
        {
            [styles.dividerNoContent]: !(content || children),
            [`-${size}`]: size,
            [`-${color}`]: color,
        },
    ) }>
        { content || children }
    </div>
);

